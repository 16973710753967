import reducer from './menu.reducer';
import * as action from './menu.action';
import * as selector from './menu.selector';
import * as constant from './menu.constant';
import initialState from './menu.initialState';
import saga from './menu.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
