export const NAME = 'toaster';

const CONTEXT = 'TOASTER';

const SHOW = `${CONTEXT}::SHOW`;
const RESET = `${CONTEXT}::RESET`;

export const ACTION_TYPES = {
  SHOW,
  RESET,
};
