import { combineReducers } from 'redux';

import transactions from './reports';
import reportsFinancing from './reportsFinancing';

const reducer = combineReducers({
  [transactions.name]: transactions.reducer,
  [reportsFinancing.name]: reportsFinancing.reducer,
});

export default reducer;
