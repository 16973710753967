import React from 'react';
import { TextField, TextFieldProps, Box, Typography } from '@mui/material';

const TextFieldLabelComponent = (props: TextFieldProps) => {
  const { label, ...restProps } = props;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {label && (
        <Typography
          variant="trasso_heading_small"
          color={restProps?.disabled ? '#717D96' : 'trasso.purple.100'}
        >
          {label}
        </Typography>
      )}
      <TextField variant="outlined" {...restProps} />
    </Box>
  );
};

export default TextFieldLabelComponent;
