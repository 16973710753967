import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';
import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldNumberComponent from '@giro/shared-components/Fields/FieldNumber.component';
import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';
import TextFieldLabelComponent from '@giro-pdv/components/TextFieldLabel.component';
import UpdateAmountComponent from '../components/UpdateAmount.component';
import ButtonPayActionsComponent from '../components/ButtonPayActions.component';

import PDVLayout from '../layouts/PDV.layout';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const FormAgent = () => {
  const formikRef: any = React.useRef(null);
  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
  };

  const initialValues = {
    type: '1º Registro',
    document: null,
    tax: null,
    fees: null,
    plate: null,
    registry: null,
    total: null,
    type_tax: 'client',
    segment: '2',
  };

  const onSubmit = () => {};

  const labelByServiceType = (type) => {
    if (['1º Registro', 'Renovação de CNH'].includes(type)) {
      return 'Taxas';
    }

    return 'Débitos';
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <>
            <UpdateAmountComponent />

            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <FieldLabelFormikComponent
                  name="type"
                  size="small"
                  label="Tipo de serviço"
                  select
                  defaultValue={'1º Registro'}
                >
                  <MenuItem value="1º Registro">1º Registro</MenuItem>
                  <MenuItem value="Licenciamento/Transferência">
                    Licenciamento/Transferência
                  </MenuItem>
                  <MenuItem value="Pagamentos de débitos">
                    Pagamentos de débitos
                  </MenuItem>
                  <MenuItem value="Renovação de CNH">Renovação de CNH</MenuItem>
                </FieldLabelFormikComponent>
                <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                  {(values.type === '1º Registro' ||
                    values.type === 'Renovação de CNH') && (
                    <FieldDocumentComponent
                      customInput={TextFieldLabelComponent}
                      name="document"
                      size="small"
                    />
                  )}

                  {(values.type === 'Licenciamento/Transferência' ||
                    values.type === 'Pagamentos de débitos') && (
                    <FieldLabelFormikComponent
                      name="plate"
                      size="small"
                      label="Placa/Renavam"
                    />
                  )}

                  {values.type === 'Renovação de CNH' && (
                    <FieldNumberComponent
                      customInput={TextFieldLabelComponent}
                      name="registry"
                      size="small"
                      label="Nº de registro"
                    />
                  )}
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns={{ lg: '1fr 1fr', xs: '1fr' }}
                  gap={4}
                >
                  <FieldCurrencyComponentDigits
                    customInput={TextFieldLabelComponent}
                    size="small"
                    label={labelByServiceType(values.type)}
                    name="tax"
                    onChange={(val) =>
                      setFieldValue('total', Number(val) + Number(values.fees))
                    }
                  />
                  <FieldCurrencyComponentDigits
                    customInput={TextFieldLabelComponent}
                    size="small"
                    label="Honorários"
                    name="fees"
                    onChange={(val) =>
                      setFieldValue('total', Number(values.tax) + Number(val))
                    }
                  />
                </Box>
                <FieldCurrencyComponent
                  customInput={TextFieldLabelComponent}
                  size="small"
                  label="Total"
                  name="total"
                  disabled
                />
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={
                      values.type_tax === 'client'
                        ? { opacity: [1, 1, 1] }
                        : { opacity: [0.5, 0.5, 0.5] }
                    }
                  >
                    <Typography variant="trasso_heading_small">R</Typography>
                    <Slider
                      disabled={values.type_tax === 'client' ? false : true}
                      step={2}
                      sx={{
                        mt: 4,
                        '& .MuiSlider-track': {
                          border: 'none',
                          backgroundColor: 'trasso.gray.40',
                        },
                        '& .MuiSlider-thumb': {
                          height: 24,
                          width: 24,
                          backgroundColor: '#fff',
                          border: '4px solid currentColor',
                        },
                        '& .MuiSlider-valueLabel': {
                          lineHeight: 1.2,
                          fontSize: 12,
                          background: 'unset',
                          padding: 0,
                          width: 32,
                          height: 32,
                          backgroundColor: 'trasso.purple.60',
                        },
                      }}
                      size="small"
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      color="secondary"
                      max={12}
                      value={selectorRedux.return}
                      onChange={(ev, value) => {
                        dispatchRedux.updateReturn(value);
                      }}
                      marks={[
                        {
                          value: 0,
                          label: (
                            <Typography
                              variant="trasso_body_small"
                              color="trasso.purple.100"
                            >
                              0
                            </Typography>
                          ),
                        },
                        {
                          value: 12,
                          label: (
                            <Typography
                              variant="trasso_body_small"
                              color="trasso.purple.100"
                            >
                              12
                            </Typography>
                          ),
                        },
                      ]}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de taxa
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="portador"
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type_tax', value);

                        dispatchRedux.updateTypeTax(value);

                        if (value === 'ec') {
                          dispatchRedux.updateReturn(0);
                        }
                      }}
                    >
                      <FormControlLabel
                        value="client"
                        checked={values.type_tax === 'client'}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'trasso.pink',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="trasso_body_medium"
                            color="trasso.purple.100"
                          >
                            Portador
                          </Typography>
                        }
                      />
                      {user?.user?.codes?.ec === 1 && (
                        <FormControlLabel
                          value="ec"
                          checked={values.type_tax === 'ec'}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: 'trasso.pink',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="trasso.purple.100"
                            >
                              EC
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>

              <ButtonPayActionsComponent
                loading={selectorRedux.transactionsLoading}
                disabledPay={
                  selectorRedux.transactionsLoading ||
                  selectorRedux.installmentSelected === null ||
                  Number((values as any).total) === 0
                }
                disabledSend={Number((values as any).total) === 0}
                onClickPay={() => {
                  dispatchRedux.transactionsServicePost({
                    ...values,
                  });
                }}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const AgentScreen = () => {
  return (
    <PDVLayout>
      <FormAgent />
    </PDVLayout>
  );
};

export default AgentScreen;
