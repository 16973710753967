const NAMESPACE = '/';
const NAMESPACE_PDV = '/pdv';
const NAMESPACE_REPORTS = '/reports';
const NAMESPACE_LOGIN = '/login';
const NAMESPACE_RECEIPT = '/receipt';
const NAMESPACE_MONITORING = '/monitoring';
const NAMESPACE_SETUP = '/setup';

const PDV_ROUTES = {
  ROOT: NAMESPACE_PDV,
  AGENT: `${NAMESPACE_PDV}/agent`,
  VEHICLE: `${NAMESPACE_PDV}/vehicle`,
  GERAL: `${NAMESPACE_PDV}/geral`,
  CEMITERIO: `${NAMESPACE_PDV}/cemiterio`,
  EDUCACAO: `${NAMESPACE_PDV}/educacao`,
  ASSESSORIA: `${NAMESPACE_PDV}/assessoria`,
  DETRAN: `${NAMESPACE_PDV}/detran`,
  BILLET: `${NAMESPACE_PDV}/billet`,
  TOTEM: `${NAMESPACE_PDV}/totem`,
  FINANCING: `${NAMESPACE_PDV}/financing`,
  MOBILE: `${NAMESPACE_PDV}/mobile`,
  RECHARGE: `${NAMESPACE_PDV}/recharge-mobile`,
  SIMULATION: `${NAMESPACE_PDV}/generate-simulation`,
  CARTORIO: `${NAMESPACE_PDV}/cartorio`,
  ABOUT: `${NAMESPACE_PDV}/about`,
};

const MONITORING_ROUTES = {
  ROOT: NAMESPACE_MONITORING,
};

const RECEIPT_ROUTES = {
  ROOT: NAMESPACE_RECEIPT,
};

const REPORTS_ROUTES = {
  ROOT: NAMESPACE_REPORTS,
  EC: `${NAMESPACE_REPORTS}/ec`,
  FINANCING: `${NAMESPACE_REPORTS}/financing`,
  CREA: `${NAMESPACE_REPORTS}/crea`,
};

const LOGIN_ROUTES = {
  ROOT: NAMESPACE_LOGIN,
  CHANGE_PASSWORD: `${NAMESPACE_LOGIN}/change-password`,
};

const EXTERNAL_PINPAD_ROUTE = 'https://127.0.0.1/agente/clisitef';

const EXTERNAL_ROUTES = {
  PINPAD: EXTERNAL_PINPAD_ROUTE,
};

const SETUP_ROUTES = {
  ROOT: NAMESPACE_SETUP,
};

export const NAMESPACES = {
  ROOT: NAMESPACE,
  PDV: NAMESPACE_PDV,
  REPORTS: NAMESPACE_REPORTS,
  LOGIN: NAMESPACE_LOGIN,
  RECEIPT: NAMESPACE_RECEIPT,
  SETUP: NAMESPACE_SETUP,
  MONITORING: NAMESPACE_MONITORING,
};

const ROUTES = {
  HOME: NAMESPACE,
  PDV: PDV_ROUTES,
  LOGIN: LOGIN_ROUTES,
  REPORTS: REPORTS_ROUTES,
  EXTERNAL: EXTERNAL_ROUTES,
  RECEIPT: RECEIPT_ROUTES,
  MONITORING: MONITORING_ROUTES,
  SETUP: SETUP_ROUTES,
};

export default ROUTES;
