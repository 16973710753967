import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';

import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';
import TextFieldLabelComponent from '@giro-pdv/components/TextFieldLabel.component';
import UpdateAmountComponent from '../components/UpdateAmount.component';
import DialogSelectAllDebitsComponent from '../components/DialogSelectAllDebits.component';
import ButtonPayActionsComponent from '../components/ButtonPayActions.component';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import PDVLayout from '../layouts/PDV.layout';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';

const FormTotem = ({ setModalSelectAll }) => {
  const dispatch = useDispatch();
  const { user } = useAuthHook();

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
  };

  const initialValues = {
    client: null,
    service: null,
    material: null,
    total: null,
    segment: '14',
    type_tax: 'client',
  };

  const onSubmit = () => {};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <UpdateAmountComponent />
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <FieldLabelFormikComponent
                  size="small"
                  name="client"
                  label="Identificação do cliente"
                />

                <FieldCurrencyComponent
                  customInput={TextFieldLabelComponent}
                  size="small"
                  label="Valor"
                  name="total"
                />
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                <ButtonPayActionsComponent
                  loading={selectorRedux.transactionsLoading}
                  onClickPay={() =>
                    dispatchRedux.transactionsServicePost({
                      ...values,
                    })
                  }
                  disabledPay={
                    selectorRedux.transactionsLoading ||
                    selectorRedux.installmentSelected === null ||
                    Number((values as any).total) === 0
                  }
                  disabledSend={Number((values as any).total) === 0}
                />
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const TotemScreen = () => {
  const [modalSelectAll, setModalSelectAll] = React.useState(false);

  return (
    <PDVLayout>
      <FormTotem setModalSelectAll={setModalSelectAll} />

      <DialogSelectAllDebitsComponent
        visible={modalSelectAll}
        handleClose={() => setModalSelectAll(false)}
      />
    </PDVLayout>
  );
};

export default TotemScreen;
