import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';
import { FieldCurrencyComponentDigits } from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldPhoneComponent from '@giro/shared-components/Fields/FieldPhone.component';
import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';
import ButtonPayActionsComponent from '../components/ButtonPayActions.component';
import TextFieldLabelComponent from '@giro-pdv/components/TextFieldLabel.component';

import PDVLayout from '../layouts/PDV.layout';

import UpdateAmountComponent from '../components/UpdateAmount.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const FormVehicle = () => {
  const dispatch = useDispatch();
  const { user } = useAuthHook();

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
  };

  const initialValues = {
    name: null,
    phone: null,
    rg: null,
    cpf: null,
    plate: null,
    company: null,
    model: null,
    year: null,
    amount: null,
    total: null,
    type_tax: 'client',
    segment: '3',
  };

  const onSubmit = () => {};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <UpdateAmountComponent />
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <Box
                  display="grid"
                  gridTemplateColumns={{ xl: '1fr', xs: '1fr' }}
                  gap={4}
                >
                  <FieldLabelFormikComponent
                    size="small"
                    name="name"
                    label="Nome"
                  />
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns={{ xl: '1fr 1fr 1fr', xs: '1fr' }}
                  gap={4}
                >
                  <FieldLabelFormikComponent
                    size="small"
                    name="rg"
                    label="RG"
                  />
                  <FieldDocumentComponent
                    customInput={TextFieldLabelComponent}
                    size="small"
                    name="cpf"
                    label="CPF"
                    isAllowed={({ value }) => String(value).length < 12}
                  />
                  <FieldPhoneComponent
                    customInput={TextFieldLabelComponent}
                    size="small"
                    name="phone"
                    label="DDD + Telefone"
                  />
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns={{ xl: '1fr 1fr', xs: '1fr' }}
                  gap={4}
                >
                  <FieldLabelFormikComponent
                    size="small"
                    name="plate"
                    label="Placa ou RENAVAM"
                  />
                  <FieldLabelFormikComponent
                    size="small"
                    name="company"
                    label="Marca"
                  />
                </Box>

                <Box
                  display="grid"
                  gridTemplateColumns={{ xl: '1fr 1fr', xs: '1fr' }}
                  gap={4}
                >
                  <FieldLabelFormikComponent
                    size="small"
                    name="model"
                    label="Modelo"
                  />
                  <FieldLabelFormikComponent
                    size="small"
                    name="year"
                    label="Ano modelo"
                  />
                </Box>

                <FieldCurrencyComponentDigits
                  customInput={TextFieldLabelComponent}
                  size="small"
                  name="amount"
                  label="Valor"
                  onChange={(val) => setFieldValue('total', Number(val))}
                />
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={
                      values.type_tax === 'client'
                        ? { opacity: [1, 1, 1] }
                        : { opacity: [0.5, 0.5, 0.5] }
                    }
                  >
                    <Typography variant="trasso_heading_small">R</Typography>
                    <Slider
                      disabled={values.type_tax === 'client' ? false : true}
                      step={2}
                      sx={{
                        mt: 4,
                        '& .MuiSlider-track': {
                          border: 'none',
                          backgroundColor: 'trasso.gray.40',
                        },
                        '& .MuiSlider-thumb': {
                          height: 24,
                          width: 24,
                          backgroundColor: '#fff',
                          border: '4px solid currentColor',
                        },
                        '& .MuiSlider-valueLabel': {
                          lineHeight: 1.2,
                          fontSize: 12,
                          background: 'unset',
                          padding: 0,
                          width: 32,
                          height: 32,
                          backgroundColor: 'trasso.purple.60',
                        },
                      }}
                      size="small"
                      defaultValue={0}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      color="secondary"
                      max={12}
                      value={selectorRedux.return}
                      onChange={(ev, value) => {
                        dispatchRedux.updateReturn(value);
                      }}
                      marks={[
                        {
                          value: 0,
                          label: (
                            <Typography
                              variant="trasso_body_small"
                              color="trasso.purple.100"
                            >
                              0
                            </Typography>
                          ),
                        },
                        {
                          value: 12,
                          label: (
                            <Typography
                              variant="trasso_body_small"
                              color="trasso.purple.100"
                            >
                              12
                            </Typography>
                          ),
                        },
                      ]}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de taxa
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="portador"
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type_tax', value);

                        dispatchRedux.updateTypeTax(value);

                        if (value === 'ec') {
                          dispatchRedux.updateReturn(0);
                        }
                      }}
                    >
                      <FormControlLabel
                        value="client"
                        checked={values.type_tax === 'client'}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'trasso.pink',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="trasso_body_medium"
                            color="trasso.purple.100"
                          >
                            Portador
                          </Typography>
                        }
                      />
                      {user?.user?.codes?.ec === 1 && (
                        <FormControlLabel
                          value="ec"
                          checked={values.type_tax === 'ec'}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: 'trasso.pink',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="trasso.purple.100"
                            >
                              EC
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                <ButtonPayActionsComponent
                  loading={selectorRedux.transactionsLoading}
                  onClickPay={() =>
                    dispatchRedux.transactionsServicePost({
                      ...values,
                    })
                  }
                  disabledPay={
                    selectorRedux.transactionsLoading ||
                    selectorRedux.installmentSelected === null ||
                    Number((values as any).total) === 0
                  }
                  disabledSend={Number((values as any).total) === 0}
                />
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const VehicleScreen = () => {
  return (
    <PDVLayout>
      <FormVehicle />
    </PDVLayout>
  );
};

export default VehicleScreen;
