import fetch from '@giro/shared-utils/fetch.util';

export default async function postApiDebtsExternalIdService(
  external_code,
  body
) {
  const url = ['debts', external_code].join('/');

  return fetch(url, {
    method: 'POST',
    ms: 'DEBTS',
    auth: true,
    body: JSON.stringify(body),
  });
}
