import reducer from './dialogSendPayment.reducer';
import * as action from './dialogSendPayment.action';
import * as selector from './dialogSendPayment.selector';
import * as constant from './dialogSendPayment.constant';
import initialState from './dialogSendPayment.initialState';
import subscriber from './dialogSendPayment.subscriber';
import saga from './dialogSendPayment.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
