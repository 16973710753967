export const NAME = 'auth';

const CONTEXT = 'AUTH';

const REFRESH_TOKEN = `${CONTEXT}::REFRESH::TOKEN`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const TOKEN_SET = `${CONTEXT}::TOKEN::SET`;
const TOKEN_UNSET = `${CONTEXT}::TOKEN::UNSET`;

const RESET = {
  STATE: RESET_STATE,
};

const TOKEN = {
  SET: TOKEN_SET,
  UNSET: TOKEN_UNSET,
};

const REFRESH = {
  TOKEN: REFRESH_TOKEN,
};

export const ACTION_TYPES = {
  RESET,
  TOKEN,
  REFRESH,
};
