import React from 'react';
import {
  TextField,
  TextFieldProps,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import Keyboard from 'react-simple-keyboard';

import KeyboardIcon from '@mui/icons-material/Keyboard';

const TextFieldLabelKeyboardComponent = (
  props: TextFieldProps & { name: string }
) => {
  const contextForm = useFormikContext();

  const { label, name, ...restProps } = props;

  const [showKeyboard, setShowKeyboard] = React.useState(false);

  const onChange = (input) => {
    contextForm.setFieldValue(name, input);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {label && (
        <Typography
          variant="trasso_heading_small"
          color={restProps?.disabled ? '#717D96' : 'trasso.purple.100'}
        >
          {label}
        </Typography>
      )}
      <Field name={props.name} {...restProps}>
        {({ field, form, meta }) => (
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'trasso.purple.100',
                '& fieldset': {
                  borderColor: 'trasso.gray.40',
                },
              },
            }}
            value={field.value}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowKeyboard(!showKeyboard)}>
                  <KeyboardIcon />
                </IconButton>
              ),
            }}
            onChange={({ target: { value } }) => {
              form.setFieldValue(name, value);
            }}
            {...restProps}
          />
        )}
      </Field>

      {showKeyboard && <Keyboard onChange={onChange} />}
    </Box>
  );
};

export default TextFieldLabelKeyboardComponent;
