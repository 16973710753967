import Cookies from 'js-cookie';
import decode from 'jwt-decode';

export function hasAuthCookie() {
  return !!Cookies.get(process.env.REACT_APP_COOKIE_KEY_TOKEN);
}

export function getAuthCookie() {
  return Cookies.get(process.env.REACT_APP_COOKIE_KEY_TOKEN);
}

export function getAuthCookieObj() {
  return hasAuthCookie() && decode(getAuthCookie());
}

export function setAuthCookie(token) {
  const tokenDecoded: any = decode(token);

  return Cookies.set(process.env.REACT_APP_COOKIE_KEY_TOKEN, token, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: new Date(tokenDecoded.exp * 1000),
  });
}

export function deleteAuthCookie() {
  return Cookies.remove(process.env.REACT_APP_COOKIE_KEY_TOKEN, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
}
