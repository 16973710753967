import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  doing: string;
};

export default async function postApiTransactionsService(body: any) {
  const { token, ...bodyData } = body;

  return fetch('transactions', {
    method: 'POST',
    ms: 'PDV',
    auth: true,
    token,
    body: JSON.stringify(bodyData),
  });
}
