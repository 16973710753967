export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1166,
  xl: 1536,
};

const PALLET_SECONDARY = {
  main: '#D675FF',
  light3: '#f7e0fe',
  light2: '#edc1fd',
  light: '#c757ff',
  dark: '#8100bd',
  dark2: '#410057',
  100: '#f7e0fe',
  200: '#edc1fd',
  300: '#c757ff',
  400: '#8100bd',
  500: '#410057',
};

const PALETTE_PRIMARY = {
  main: '#240045',
  light: '#c491ff',
  light2: '#ebd1ff',
  light3: '#f6ebfe',
  dark: '#8806de',
  dark2: '#570080',
  100: '#f6ebfe',
  200: '#ebd1ff',
  300: '#c491ff',
  400: '#8806de',
  500: '#570080',
};

const PALLET_GRAY = {
  main: '#F5F0F7',
  light: '#F2F5F6',
  dark: '#32414C',
  black: '#000F1A',
  100: '#F2F5F6',
  200: '#DBE1E5',
  300: '#9FAAB1',
  400: '#6C7A84',
  500: '#32414C',
};

const PALLET_WARNING = {
  main: '#FFAB00',
  light: '#FFD070',
  light2: '#FFE7B8',
  light3: '#FFF7E6',
  dark: '#BF840B',
  dark2: '#664400',
  100: '#FFF7E5',
  200: '#FFE7B8',
  300: '#FFD070',
  400: '#BF840B',
  500: '#664400',
};

const PALLET_SUCCESS = {
  main: '#34BE84',
  light: '#83DDB7',
  light2: '#C0F1DC',
  light3: '#DDF8ED',
  dark: '#229464',
  dark2: '#114B32',
  100: '#DDF8ED',
  200: '#C0F1DC',
  300: '#83DDB7',
  400: '#229464',
  500: '#114B32',
};

const PALLET_ERROR = {
  main: '#F44336',
  light: '#F99790',
  light2: '#FCC4C0',
  light3: '#FEE0DE',
  dark: '#CB180B',
  dark2: '#790E07',
  100: '#FEE0DE',
  200: '#FCC4C0',
  300: '#F99790',
  400: '#CB180B',
  500: '#790E07',
};

const PALLET_INFO = {
  main: '#0362F0',
  light: '#3385FF',
  light2: '#B8D4FF',
  light3: '#E6F0FF',
  dark: '#003E99',
  dark2: '#002152',
  100: '#E6F0FF',
  200: '#B8D4FF',
  300: '#3385FF',
  400: '#003E99',
  500: '#002152',
};

const PALLET_TRASSO_PINK = '#D776FF';

const PALLET_TRASSO_WHITE = {
  0: '#FFFFFF',
};

const PALLET_TRASSO_GRAY = {
  20: '#F4F0F7',
  30: '#ECE5F1',
  40: '#E4DAEB',
};

const PALLET_TRASSO_PURPLE = {
  60: '#5A108D',
  80: '#360067',
  90: '#360067',
  100: '#240045',
};

const PALLET_TRASSO_YELLOW = '#F9C357';
const PALLET_TRASSO_BLUE = '#7A78FA';
const PALLET_TRASSO_RED = '#FC6447';

const PALLET_TRASSO_SITE = {
  branco: '#FFFFFF',
  roxo: '#240045',
  rosa: '#D776FF',
  roxo2: '#360067',
  base: '#F4F0F7',
  base2: '#E4DAEB',
  vermelho: '#FF5832',
  verde: '#9DCC12',
};

const PALLET_EVORA = {
  10: '#7FB8CE',
  20: '#55849A',
  30: '#3B5A68',
  40: '#2B3F48',
  50: '#1C2324',
  grey: '#3B3B3B',
  white: '#FEFFFE',
};

const PALETTE = {
  primary: PALETTE_PRIMARY,
  secondary: PALLET_SECONDARY,
  gray: PALLET_GRAY,
  warning: PALLET_WARNING,
  success: PALLET_SUCCESS,
  error: PALLET_ERROR,
  info: PALLET_INFO,
  trasso: {
    pink: PALLET_TRASSO_PINK,
    white: PALLET_TRASSO_WHITE,
    gray: PALLET_TRASSO_GRAY,
    purple: PALLET_TRASSO_PURPLE,
    yellow: PALLET_TRASSO_YELLOW,
    blue: PALLET_TRASSO_BLUE,
    red: PALLET_TRASSO_RED,
  },
  evora: PALLET_EVORA,
  trasso_site: PALLET_TRASSO_SITE,
};

const TYPOGRAPHY_HEADER_LG = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '62px',
  fontWeight: 700,
  lineHeight: '70px',
};

const TYPOGRAPHY_HEADER = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '54px',
};

const TYPOGRAPHY_HEADER_SM = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
};

const TYPOGRAPHY_BODY_LG = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '28px',
};

const TYPOGRAPHY_BODY_MD = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28px',
};

const TYPOGRAPHY_BODY = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
};

const TYPOGRAPHY_BODY_SM = {
  fontFamily: "'Work Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
};

const TYPOGRAPHY_TRASSO_BODY_MEDIUM = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '-0.01em',
  fontFeatureSettings: "'calt' off",
};

const TYPOGRAPHY_TRASSO_BODY_SMALL = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '-0.01em',
  fontFeatureSettings: "'calt' off",
};

const TYPOGRAPHY_TRASSO_BODY_EXTRA_SMALL = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '5px',
  fontFeatureSettings: "'calt' off",
};

const TYPOGRAPHY_TRASSO_BUTTON = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  fontFeatureSettings: "'calt' off",
};

const TYPOGRAPHY_TRASSO_HEADING_MEDIUM = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '130%',
};

const TYPOGRAPHY_TRASSO_HEADING_SMALL = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '-0.01em',
  fontFeatureSettings: "'calt' off",
};

const TYPOGRAPHY_TRASSO_HEADING_EXTRA_SMALL = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '150%',
  fontFeatureSettings: "'calt' off",
};

const TYPOGRAPHY_TRASSO = {
  trasso_body_medium: TYPOGRAPHY_TRASSO_BODY_MEDIUM,
  trasso_body_small: TYPOGRAPHY_TRASSO_BODY_SMALL,
  trasso_body_extra_small: TYPOGRAPHY_TRASSO_BODY_EXTRA_SMALL,
  trasso_button: TYPOGRAPHY_TRASSO_BUTTON,
  trasso_heading_medium: TYPOGRAPHY_TRASSO_HEADING_MEDIUM,
  trasso_heading_small: TYPOGRAPHY_TRASSO_HEADING_SMALL,
  trasso_heading_extra_small: TYPOGRAPHY_TRASSO_HEADING_EXTRA_SMALL,
};

const TYPOGRAPHY_TRASSO_SITE_H1 = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  letterSpacing: '-0.015em',
  [`@media (min-width:${breakpoints.xs}px)`]: {
    fontSize: '38px',
    lineHeight: '40px',
  },
  [`@media (min-width:${breakpoints.md}px)`]: {
    fontSize: '58px',
    lineHeight: '60px',
  },
};

const TYPOGRAPHY_TRASSO_SITE_H2 = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '40px',
  lineHeight: '44px',
  letterSpacing: '-0.02em',
  [`@media (max-width:${breakpoints.sm}px)`]: {
    fontSize: '28px',
  },
};

const TYPOGRAPHY_TRASSO_SITE_H3 = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '28px',
  letterSpacing: '-0.015em',
  [`@media (max-width:${breakpoints.sm}px)`]: {
    fontSize: '18px',
  },
};

const TYPOGRAPHY_TRASSO_SITE_H3_LIGHT = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '24px',
  lineHeight: '28px',
  letterSpacing: '-0.015em',
};

const TYPOGRAPHY_TRASSO_SITE_H4 = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '23px',
  letterSpacing: '-0.015em',
};

const TYPOGRAPHY_TRASSO_SITE_BOTAO = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '23px',
};

const TYPOGRAPHY_TRASSO_SITE_SELOS = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.3em',
  textTransform: 'uppercase',
};

const TYPOGRAPHY_TRASSO_SITE_CORPO = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '140%',
};

const TYPOGRAPHY_TRASSO_SITE_CAPTION = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '-0.01em',
  textTransform: 'uppercase',
};

const TYPOGRAPHY_TRASSO_SITE = {
  trasso_site_h1: TYPOGRAPHY_TRASSO_SITE_H1,
  trasso_site_h2: TYPOGRAPHY_TRASSO_SITE_H2,
  trasso_site_h3: TYPOGRAPHY_TRASSO_SITE_H3,
  trasso_site_h3_light: TYPOGRAPHY_TRASSO_SITE_H3_LIGHT,
  trasso_site_h4: TYPOGRAPHY_TRASSO_SITE_H4,
  trasso_site_botao: TYPOGRAPHY_TRASSO_SITE_BOTAO,
  trasso_site_selos: TYPOGRAPHY_TRASSO_SITE_SELOS,
  trasso_site_corpo: TYPOGRAPHY_TRASSO_SITE_CORPO,
  trasso_site_caption: TYPOGRAPHY_TRASSO_SITE_CAPTION,
};

const TYPOGRAPHY = {
  fontFamily: "'Work Sans', sans-serif",
  header_lg: TYPOGRAPHY_HEADER_LG,
  header: TYPOGRAPHY_HEADER,
  header_sm: TYPOGRAPHY_HEADER_SM,
  body_lg: TYPOGRAPHY_BODY_LG,
  body_md: TYPOGRAPHY_BODY_MD,
  body: TYPOGRAPHY_BODY,
  body_sm: TYPOGRAPHY_BODY_SM,
  h1: TYPOGRAPHY_HEADER_LG,
  h2: TYPOGRAPHY_HEADER,
  h3: TYPOGRAPHY_HEADER_SM,
  body1: TYPOGRAPHY_BODY_LG,
  body1700: {
    ...TYPOGRAPHY_BODY_LG,
    fontWeight: 'bold',
  },
  body2: TYPOGRAPHY_BODY_MD,
  body2600: {
    ...TYPOGRAPHY_BODY_MD,
    fontWeight: 600,
  },
  subtitle1: TYPOGRAPHY_BODY,
  subtitle1700: { ...TYPOGRAPHY_BODY, fontWeight: 700 },
  subtitle2: TYPOGRAPHY_BODY_SM,
  subtitle2700: {
    ...TYPOGRAPHY_BODY_SM,
    fontWeight: 700,
  },
  ...TYPOGRAPHY_TRASSO,
  ...TYPOGRAPHY_TRASSO_SITE,
};

const theme = {
  breakpoints: {
    values: breakpoints,
  },
  palette: PALETTE,
  typography: TYPOGRAPHY,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          legend: {},
        },
      },
    },
  },
};

export default theme;
