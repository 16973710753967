import reducer from './monitorings.reducer';
import * as action from './monitorings.action';
import * as selector from './monitorings.selector';
import * as constant from './monitorings.constant';
import initialState from './monitorings.initialState';
import saga from './monitorings.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
