import { createReducer } from '@reduxjs/toolkit';

import initialState from './clients.initialState';
import { ACTION_TYPES } from './clients.constant';

const handleResetState = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchError = (state) => {
  state.loading = false;
};

const handleFetchSuccess = (state, action) => {
  state.loading = false;
  state.payload = action.payload;
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.FETCH.SUCCESS]: handleFetchSuccess,
});
