import React from 'react';
import { flow } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';

import Logo from '@giro/shared-components/Logo.component';

import messagesSchema from '@giro/shared-constants/messagesSchema.constant';

import useDialogHook from '@giro-pdv/hooks/useDialogRedux.hook';

import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import dialogPay from '../store/dialogs/dialogPay';
import pinpad from '../store/pinpad';
import transactions from '../store/transactions';
import dialogReceipt from '../../../store/dialogReceipt';

export default function DialogPayComponent() {
  const [confirm, setConfirm] = React.useState(false);
  const [loadingCancel, setLoadingCancel] = React.useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    servicePost: flow(dialogPay.action.servicePost, dispatch),
    servicePostStart: flow(pinpad.action.servicePostStart, dispatch),
    servicePostContinue: flow(pinpad.action.servicePostContinue, dispatch),
    updateSession: flow(pinpad.action.updateSession, dispatch),
    resetState: flow(pinpad.action.resetState, dispatch),
    servicePatchTransaction: flow(transactions.action.servicePatch, dispatch),
  };

  const transactionsState = useSelector(transactions.selector.selectState);

  const selectorRedux = {
    loading: useSelector(dialogPay.selector.selectLoading),
    data: useSelector(pinpad.selector.selectData),
    message: useSelector(pinpad.selector.selectMessage),
    error: useSelector(pinpad.selector.selectError),
    session: useSelector(pinpad.selector.selectSession),
    present: useSelector(pinpad.selector.selectPresent),
    state: useSelector(pinpad.selector.selectState),
  };

  const { handleClose, visible } = useDialogHook(dialogPay);

  const { handleOpen } = useDialogHook(dialogReceipt);

  const schema = Yup.object({
    pinpad: Yup.string().required(messagesSchema.REQUIRED),
    printer: Yup.string().required(messagesSchema.REQUIRED),
  });

  const initialValues = {
    pinpad: '1',
    printer: '1',
  };

  const onSubmit = async (values) => {
    dispatchRedux.servicePost(values);

    return true;
  };

  React.useEffect(() => {
    if (visible) {
      if (selectorRedux.present) {
        dispatchRedux.servicePostStart();
      } else {
        dispatchRedux.servicePatchTransaction(33);
      }
    } else {
      setLoadingCancel(false);
      setConfirm(false);
      dispatchRedux.resetState();
    }
  }, [visible]);

  const [hasError] = selectorRedux.error;

  const lastData = selectorRedux?.data?.slice(-1)?.pop();

  const pinpadDisplay = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography color="trasso.purple.90" variant="trasso_heading_small">
          Tela de pagamento
        </Typography>
      </Box>

      <Box
        height="250px"
        border="1px solid transparent"
        borderColor="trasso.gray.30"
        borderRadius="6px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={5}
      >
        <Box>
          <Logo variant="new" width="288px" />
        </Box>
        <Typography variant="trasso_heading_small" color="trasso.purple.80">
          {selectorRedux.present && selectorRedux?.message}

          {!selectorRedux.present && 'Nenhum pinpad encontrado.'}
        </Typography>
      </Box>
    </Box>
  );

  const pinpadForm = (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
      <Box gridColumn="span 2">
        <FieldLabelFormikComponent
          name="pinpad"
          size="small"
          label="Pin Pad"
          select
        >
          <MenuItem value="1">COM13</MenuItem>
        </FieldLabelFormikComponent>
      </Box>
      <FieldLabelFormikComponent
        name="printer"
        size="small"
        label="Impressora"
        select
      >
        <MenuItem value="1">Selecionar</MenuItem>
      </FieldLabelFormikComponent>
      <Button
        variant="outlined"
        color="primary"
        sx={{ height: 45, alignSelf: 'flex-end' }}
        endIcon={
          <svg
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <use href="/feather-sprite.svg#printer" />
          </svg>
        }
      >
        Imprimir
      </Button>
      <Button variant="contained" color="primary" sx={{ height: 45 }}>
        Pagar
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={{ height: 45 }}
        onClick={handleClose}
      >
        Cancelar
      </Button>
    </Box>
  );

  const pinpadActions = (
    <>
      {!selectorRedux.present && (
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          loading={transactionsState.loading}
        >
          Tentar conectar-se ao pinpad novamente
        </LoadingButton>
      )}

      {selectorRedux.state.transaction_status === 'transaction_rejected' && (
        <Button variant="outlined" onClick={handleClose}>
          Fechar
        </Button>
      )}

      {selectorRedux.state.transaction_status === 'transaction_approved' && (
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            handleOpen(transactionsState?.data?.meta);
          }}
        >
          Ver recibo
        </Button>
      )}

      {selectorRedux.state.transaction_status === 'transaction_processing' && (
        <>
          {!confirm && (
            <LoadingButton
              variant="outlined"
              onClick={() => setConfirm(true)}
              loading={transactionsState.loading}
            >
              Cancelar
            </LoadingButton>
          )}

          {confirm && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                color="trasso.purple.90"
                variant="trasso_heading_small"
              >
                Você tem certeza?
              </Typography>
              <Box display="flex" gap={3}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={loadingCancel}
                  onClick={() => {
                    setLoadingCancel(true);

                    dispatchRedux.updateSession({
                      continua: -1,
                    });

                    dispatchRedux.servicePostContinue('');
                  }}
                >
                  Sim
                </LoadingButton>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setLoadingCancel(false);
                    setConfirm(false);
                  }}
                  fullWidth
                  disabled={loadingCancel}
                >
                  Não
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );

  return (
    <Dialog open={visible} onClose={() => {}}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={schema}
      >
        {({ errors, values, setFieldValue, submitForm }) => (
          <Form>
            <Box
              bgcolor="white"
              width={600}
              display="flex"
              flexDirection="column"
              p={4}
              gap={3}
            >
              {pinpadDisplay}
              {pinpadActions}
              {/* {pinpadForm} */}
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
