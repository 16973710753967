export const NAME = 'login';

const CONTEXT = 'LOGIN::LOGIN';

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_ERROR = `${CONTEXT}::RESET::ERROR`;

const ERROR = `${CONTEXT}::ERROR`;

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const RESET = {
  STATE: RESET_STATE,
  ERROR: RESET_ERROR,
};

export const ACTION_TYPES = {
  FETCH,
  RESET,
  ERROR,
};
