import { all } from 'redux-saga/effects';

import dialogSendPayment from './dialogChangeEmail';

function* watch() {
  yield all([dialogSendPayment.saga.watch()]);
}

export default {
  watch,
};
