import reducer from './monitoring.reducer';
import * as selector from './monitoring.selector';
import * as constant from './monitoring.constant';
import subscriber from './monitoring.subscriber';
import saga from './monitoring.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
