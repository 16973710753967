export const NAME = 'receipt';

const CONTEXT = 'RECEIPT::RECEIPT';

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_FILTERS = `${CONTEXT}::RESET::FILTERS`;

const SERVICE = {
  GET: SERVICE_GET,
};

const RESET = {
  STATE: RESET_STATE,
  FILTERS: RESET_FILTERS,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  UPDATE,
  SERVICE,
};
