import React from 'react';
import { flow } from 'lodash';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import useDialogHook from '@giro-pdv/hooks/useDialogRedux.hook';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldPassword from '@giro/shared-components/Fields/FieldPassword.component';

import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import BoxLoginLayout from '../layout/BoxLogin.layout';

import login from '../store/login';

import dialogChangeEmail from '../store/dialogs/dialogChangeEmail';

const FormLogin = () => {
  const { handleOpen } = useDialogHook(dialogChangeEmail);

  const dispatch = useDispatch();

  const dispatchRedux = {
    fetchStart: flow(login.action.fetchStart, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(login.selector.selectLoading),
  };

  const schema = Yup.object({
    email: Yup.string().email(messages.EMAIL).required(messages.REQUIRED),
    password: Yup.string().required(messages.REQUIRED),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (values) => {
    dispatchRedux.fetchStart(values);

    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnMount
    >
      {({ errors, submitForm, isSubmitting }) => (
        <Form onSubmit={submitForm}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" flexDirection="column" gap={3}>
              <FieldLabelFormikComponent
                name="email"
                type="email"
                label="Email"
                placeholder="Seu email"
              />
              <FieldPassword
                component={FieldLabelFormikComponent}
                name="password"
                label="Senha"
                placeholder="Sua senha"
              />
            </Box>
            {/* <Link
            component="button"
            variant="trasso_body_extra_small"
            align="right"
            underline="hover"
            onClick={() => handleOpen({})}
          >
            Recuperar senha
          </Link> */}
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              disabled={selectorRedux.loading || Object.keys(errors).length > 0}
              onClick={submitForm}
              loading={selectorRedux.loading}
            >
              Entrar
            </LoadingButton>
            {/* <Box display="flex" flexDirection="column" gap={4} mt={3}>
            <Box
              display="flex"
              flexDirection="column"
              position="relative"
              justifyContent="center"
            >
              <Box
                alignSelf="center"
                bgcolor="white"
                position="relative"
                zIndex={1}
                px={1}
                display="flex"
              >
                <Typography variant="trasso_body_extra_small">
                  ou entre com
                </Typography>
              </Box>
              <Divider
                sx={{ position: 'absolute', width: '100%', zIndex: 0 }}
              />
            </Box>
            <Box display="flex" justifyContent="center" gap={2}>
              <img src="/facebook-square.png" />
              <img src="/twitter-square.png" />
              <img src="/linkedin.png" />
              <img src="/google-plus-square.png" />
            </Box>
          </Box> */}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const LoginScreen = () => {
  return (
    <BoxLoginLayout>
      <FormLogin />
    </BoxLoginLayout>
  );
};

export default LoginScreen;
