import reducer from './login.reducer';
import saga from './login.saga';
import * as action from './login.action';
import * as selector from './login.selector';
import * as constant from './login.constant';
// import subscriber from './login.subscriber';
import initialState from './login.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  // subscriber,
  name: constant.NAME,
};
