import { flow } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import getApiMonitoringsService from '@giro/shared-services/monitoring/getApiMonitorings.service';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

function* handleServiceGet() {
  const showToaster = flow(toaster.action.show, put);

  const filters = yield select(currentStore.selector.selectFilters);

  yield put(currentStore.action.fetchStart());

  try {
    const [success, result] = yield call(getApiMonitoringsService, filters);

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));
  } catch (e: any) {
    yield put(currentStore.action.fetchError());

    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }
}
function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    currentStore.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGet
  );
}

export default {
  watch,
};
