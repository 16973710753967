import { createReducer } from '@reduxjs/toolkit';

import initialState from './debts.initialState';
import { ACTION_TYPES } from './debts.constant';

const handleResetState = () => initialState;

const handleResetSelected = (state) => {
  state.selected = initialState.selected;
};

const handleResetFilters = (state) => {
  state.filters = initialState.filters;
};

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchError = (state) => {
  state.error = true;
  state.loading = false;
};

const handleFetchSuccess = (state, action) => {
  state.loading = false;
  state.error = false;
  state.data = action.payload;
};

const handleUpdateFilters = (state, action) => {
  state.loading = true;
  state.filters = Object.assign({}, state.filters, action.filters);
};

const handleUpdateSelected = (state, action) => {
  state.selected = action.selected;
};

const handleSelect = (state, action) => {
  state.selected = [...state.selected, action.payload];
};

const handleSelectAll = (state) => {
  state.selected = [...(state?.data?.results || [])];
};

const handleUnSelect = (state, action) => {
  state.selected = [
    ...state.selected.filter((s) => s.reference !== action.payload.reference),
  ];
};

const handleUnSelectAll = (state) => {
  state.selected = [];
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.SELECTED]: handleResetSelected,
  [ACTION_TYPES.UPDATE.SELECTED]: handleUpdateSelected,
  [ACTION_TYPES.SELECT]: handleSelect,
  [ACTION_TYPES.UNSELECT]: handleUnSelect,
  [ACTION_TYPES.SELECT_ALL]: handleSelectAll,
  [ACTION_TYPES.UNSELECT_ALL]: handleUnSelectAll,
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.FETCH.SUCCESS]: handleFetchSuccess,
  [ACTION_TYPES.RESET.FILTERS]: handleResetFilters,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
});
