import { flow } from 'lodash';
import { NAME } from './debts.constant';

import { selectState as selectStatePrev } from '../debts.selector';

const selectStateKey = (state) => state[NAME];
const selectDataKey = (state) => state.data;
const selectSelectedKey = (state) => state.selected;
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectFiltersKey = (state) => state.filters;

export const selectState = flow(selectStatePrev, selectStateKey);
export const selectData = flow(selectState, selectDataKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectSelected = flow(selectState, selectSelectedKey);
export const selectFilters = flow(selectState, selectFiltersKey);
