import { ACTION_TYPES } from './sync.constant';

export const servicePost = () => ({
  type: ACTION_TYPES.SERVICE.POST,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchSuccess = (payload) => ({
  type: ACTION_TYPES.FETCH.SUCCESS,
  payload,
});

export const fetchError = () => ({
  type: ACTION_TYPES.FETCH.ERROR,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const updateLogSocket = (payload) => ({
  type: ACTION_TYPES.UPDATE.LOG_SOCKET,
  payload,
});
