import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  email: string;
  password: string;
};

export default async function postApiAccountsService(body: BodyType) {
  return fetch('accounts', {
    method: 'POST',
    ms: 'PDV',
    auth: true,
    body: JSON.stringify(body),
  });
}
