import { combineReducers } from 'redux';

import router from '@giro/shared-store/router';
import toaster from '@giro/shared-store/toaster';
import auth from '@giro/shared-store/auth';

import pdv from '@giro-pdv/modules/pdv/store';
import login from '@giro-pdv/modules/login/store';
import reports from '@giro-pdv/modules/reports/store';
import receipt from '@giro-pdv/modules/receipt/store';
import monitoring from '@giro-pdv/modules/monitoring/store';

import dialogReceipt from './dialogReceipt';
import menu from './menu';

const appReducer = combineReducers({
  [router.name]: router.reducer,
  [monitoring.name]: monitoring.reducer,
  [toaster.name]: toaster.reducer,
  [pdv.name]: pdv.reducer,
  [receipt.name]: receipt.reducer,
  [reports.name]: reports.reducer,
  [login.name]: login.reducer,
  [auth.name]: auth.reducer,
  [dialogReceipt.name]: dialogReceipt.reducer,
  [menu.name]: menu.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
