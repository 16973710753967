import { createReducer } from '@reduxjs/toolkit';

import initialState from './domain.initialState';
import { ACTION_TYPES } from './domain.constant';

const handleResetState = () => initialState;

const handleResetFilters = (state) => {
  state.filters = initialState.filters;
};

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchError = (state) => {
  state.loading = false;
};

const handleFetchSuccess = (state, action) => {
  state.loading = false;
  state.data = action.payload;
};

const handleUpdateFilters = (state, action) => {
  state.loading = true;
  state.filters = Object.assign({}, state.filters, action.filters);
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.FETCH.SUCCESS]: handleFetchSuccess,
  [ACTION_TYPES.RESET.FILTERS]: handleResetFilters,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
});
