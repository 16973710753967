import { flow } from 'lodash';

import { NAME } from './tablePayment.constant';

import { selectState as selectStatePDV } from '../pdv.selector';

const selectStateKey = (state) => state[NAME];
const selectAmountKey = (state) => state.amount;
const selectLoadingKey = (state) => state.loading;
const selectInstallmentSelectedKey = (state) => state.installmentSelected;
const selectReturnKey = (state) => state.return;
const selectTypeTaxKey = (state) => state.typeTax;

export const selectState = flow(selectStatePDV, selectStateKey);
export const selectAmount = flow(selectState, selectAmountKey);
export const selectInstallmentSelected = flow(
  selectState,
  selectInstallmentSelectedKey
);
export const selectTypeTax = flow(selectState, selectTypeTaxKey);
export const selectReturn = flow(selectState, selectReturnKey);
export const selectLoading = flow(selectState, selectLoadingKey);
