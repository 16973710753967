import { flow } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import fetch from '@giro/shared-utils/fetch.util';

import toaster from '@giro/shared-store/toaster';
import clients from '../clients';
import currentStore from '.';

function* handleServicePost() {
  const client = yield select(clients.selector.selectPayload);

  const { external_terminal } = client || {};

  if (!external_terminal) {
    return false;
  }

  const showToaster = flow(toaster.action.show, put);

  yield put(currentStore.action.fetchStart());

  try {
    const [success, result] = yield call(async () => {
      return fetch(`linkstates`, {
        method: 'POST',
        ms: 'PDV',
        auth: true,
      });
    });

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));
  } catch (e) {
    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
}

export default {
  watch,
};
