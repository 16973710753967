import { flow } from 'lodash';

import { NAME } from './monitorings.constant';

import { selectState as selectStatePdv } from '../monitoring.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectDataKey = (state) => state.data;
const selectFiltersKey = (state) => state.filters;

export const selectState = flow(selectStatePdv, selectStateKey);
export const selectFilters = flow(selectState, selectFiltersKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectData = flow(selectState, selectDataKey);
