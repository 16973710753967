import { flow } from 'lodash';

import { NAME } from './login.constant';

import { selectState as selectStateLogin } from '../login.selector';

export const selectStateKey = (state) => state[NAME];

const selectDataKey = (state) => state.data;
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;

export const selectState = flow(selectStateLogin, selectStateKey);
export const selectData = flow(selectState, selectDataKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectError = flow(selectState, selectErrorKey);
