const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  filters: {
    operatorId: '',
    historyId: '',
  },
};

export default initialState;
