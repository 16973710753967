export const NAME = 'sync';

const CONTEXT = 'PDV::SYNC';

const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const UPDATE_LOG_SOCKET = `${CONTEXT}::UPDATE::LOG_SOCKET`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const RESET = {
  STATE: RESET_STATE,
};

const SERVICE = {
  POST: SERVICE_POST,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

const UPDATE = {
  LOG_SOCKET: UPDATE_LOG_SOCKET,
};

export const ACTION_TYPES = {
  RESET,
  SERVICE,
  FETCH,
  UPDATE,
};
