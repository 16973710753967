import auth from '.';

let nextState = {};
let prevState = {};

const reloadWhenLogoff = () => {
  const prevSignedIn = auth.selector.selectSignedIn(prevState);
  const nextSignedIn = auth.selector.selectSignedIn(nextState);

  if (nextSignedIn === false && prevSignedIn === true) {
    return setTimeout(() => window.location.reload());
  }
};

const subscribe = ({ getState }) => {
  nextState = getState();

  reloadWhenLogoff();

  prevState = nextState;
};

export default {
  subscribe,
};
