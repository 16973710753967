import React from 'react';
import { useSelector } from 'react-redux';

import addScriptUtil from '@giro-pdv/utils/addScript.util';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import clients from '@giro-pdv/modules/pdv/store/clients';

import { segmentNormalizeInvertConstant } from '@giro-pdv/constants/segmentNormalize.constant';

const useJivoChatHook = (id) => {
  const selectClient = useSelector(clients.selector.selectState);

  const { user, signedIn } = useAuthHook();

  const [loaded, setLoaded] = React.useState(false);

  const interval = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    addScriptUtil(`https://code.jivosite.com/widget/${id}`);

    interval.current = setInterval(() => {
      if ((window as any).jivo_api) {
        setLoaded(true);
        clearInterval(interval.current as NodeJS.Timeout);
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (!loaded || !signedIn || selectClient.loading === true) {
      return undefined;
    }

    const { cdCliente, nmFantasia } = selectClient?.payload || {};
    const { name, email, codes } = user?.user || {};
    const { segment } = codes || {};

    const fullName = `${cdCliente} - ${nmFantasia} - ${name}`;
    const segmentNormalized = segmentNormalizeInvertConstant[segment];

    (window as any).jivo_api.setContactInfo({
      name: fullName,
      email,
      description: segmentNormalized,
    });
  }, [loaded, signedIn, selectClient]);
};

export default useJivoChatHook;
