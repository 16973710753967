import React from 'react';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const useIsTotemHook = () => {
  const { user } = useAuthHook();

  const isTotem = String(user?.user?.codes?.segment).includes('14');

  return isTotem;
};

export default useIsTotemHook;
