export const NAME = 'dialogSendPayment';

const CONTEXT = 'PDV::DIALOGS::SEND_PAYMENT';

const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const UPDATE_REFERENCE_ID = `${CONTEXT}::UPDATE::REFERENCE_ID`;

const UPDATE = {
  REFERENCE_ID: UPDATE_REFERENCE_ID,
};

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

const SERVICE = {
  POST: SERVICE_POST,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  SET,
  RESET,
  SERVICE,
  FETCH,
  UPDATE,
};
