import React from 'react';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const useIsCreaHook = () => {
  const { user } = useAuthHook();

  const isCrea = String(user?.user?.codes?.segment).includes('8');

  return isCrea;
};

export default useIsCreaHook;
