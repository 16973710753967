import reducer from './receipt.reducer';
import * as selector from './receipt.selector';
import * as constant from './receipt.constant';
import subscriber from './receipt.subscriber';
import saga from './receipt.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
