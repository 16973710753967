import { combineReducers } from 'redux';

import dialogSendPayment from './dialogSendPayment';
import dialogPay from './dialogPay';
import dialogPayTerminal from './dialogPayTerminal';

const reducer = combineReducers({
  [dialogPayTerminal.name]: dialogPayTerminal.reducer,
  [dialogPay.name]: dialogPay.reducer,
  [dialogSendPayment.name]: dialogSendPayment.reducer,
});

export default reducer;
