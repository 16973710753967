import { flow } from 'lodash';

import { NAME } from './clients.constant';

import { selectState as selectStatePdv } from '../pdv.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectPayloadKey = (state) => state.payload;

export const selectState = flow(selectStatePdv, selectStateKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectPayload = flow(selectState, selectPayloadKey);
