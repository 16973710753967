import { createReducer } from '@reduxjs/toolkit';

import initialState from './dialogChangeEmail.initialState';
import { ACTION_TYPES } from './dialogChangeEmail.constant';

const handleOpen = (state, action) => {
  state.visible = true;
  state.payload = action.payload;
};

const handleClose = (state) => {
  state.visible = false;
  state.payload = null;
};

const handleReset = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.SET.OPEN]: handleOpen,
  [ACTION_TYPES.SET.CLOSE]: handleClose,
  [ACTION_TYPES.RESET]: handleReset,
});
