import { all } from 'redux-saga/effects';

import dialogSendPayment from './dialogSendPayment';
import dialogPay from './dialogPay';
import dialogPayTerminal from './dialogPay';

function* watch() {
  yield all([
    dialogSendPayment.saga.watch(),
    dialogPay.saga.watch(),
    dialogPayTerminal.saga.watch(),
  ]);
}

export default {
  watch,
};
