import { all } from 'redux-saga/effects';

import operators from './operators';
import amount from './amount';

function* watch() {
  yield all([operators.saga.watch(), amount.saga.watch()]);
}

export default {
  watch,
};
