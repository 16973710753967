import { flow } from 'lodash';

import { NAME } from './dialogPay.constant';

import { selectState as selectStateDialog } from '../dialogs.selector';

const selectStateKey = (state) => state[NAME];
const selectVisibleKey = (state) => state.visible;
const selectLoadingKey = (state) => state.loading;
const selectPayloadKey = (state) => state.payload;

export const selectState = flow(selectStateDialog, selectStateKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectVisible = flow(selectState, selectVisibleKey);
export const selectPayload = flow(selectState, selectPayloadKey);
