const initialState = {
  // visible: true,
  visible: null,
  data: null,
  error: [null, null],
  loading: null,
  // payload: 124109971,
  payload: null,
};

export default initialState;
