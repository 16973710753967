import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';

import Logo from '@giro/shared-components/Logo.component';

import useDialogHook from '@giro-pdv/hooks/useDialogRedux.hook';

import dialogPayTerminal from '../store/dialogs/dialogPayTerminal';

import websocketUtil from '@giro-pdv/utils/websocket.util';

import sync from '../store/sync';
import transactions from '../store/transactions';
import dialogReceipt from '@giro-pdv/store/dialogReceipt';

export default function DialogPayTerminalComponent() {
  const dispatch = useDispatch();

  const firstLoading = React.useRef(true);

  const [message, setMessage] = React.useState('');
  const [meta, setMeta] = React.useState('');
  const [sucessPayment, setSuccessPayment] = React.useState(false);

  const { handleClose, visible, payload } = useDialogHook(dialogPayTerminal);

  const syncState = useSelector(sync.selector.selectState);
  const transactionsState = useSelector(transactions.selector.selectState);

  React.useEffect(() => {
    if (visible === true) {
      setMessage('Aguardando seleção de pagamento');

      websocketUtil.addEvent(payload?.linkedkey, {
        event: 'pdv::init',
        payload: {
          amount: payload?.amount,
          feesData: payload?.feesData,
          typeTax: payload?.typeTax,
          return: payload?.return,
          retornoGiro: payload?.retornoGiro,
          linkedkey: payload?.linkedkey,
          state: payload?.state,
          transaction: payload?.transaction,
        },
      });
    } else {
      setMeta('');
      setSuccessPayment(false);
    }
  }, [visible]);

  React.useEffect(() => {
    const lastLogSocket = syncState.logSocket[syncState.logSocket.length - 1];

    const lastStates = lastLogSocket?.states[lastLogSocket?.states.length - 1];

    if (lastStates?.event === 'pdv-terminal::method_payment') {
      if (lastStates?.payload?.method_payment === 'card') {
        setMessage(
          'Forma de pagamento selecionada: Cartão... Aguardando seleção da parcela.'
        );
      }

      if (lastStates?.payload?.method_payment === 'pix') {
        dispatch(
          transactions.action.servicePost({
            isPix: true,
            ...payload.state,
          })
        );

        setMessage(
          'Forma de pagamento selecionada: PIX... Aguardando pagamento.'
        );
      }
    }

    if (lastStates?.meta) {
      setMeta(lastStates.meta);
    }

    if (lastStates?.event === 'pdv-terminal::payment::success') {
      setMessage('Pagamento realizado com sucesso.');

      setSuccessPayment(true);
    }

    if (lastStates?.event === 'pdv-terminal::payment::error') {
      setMessage('Aconteceu um erro com o pagamento.');
    }

    if (lastStates?.state === 'completed') {
      dispatch(transactions.action.servicePatch(3));
    }
  }, [syncState.logSocket]);

  const pinpadDisplay = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography color="trasso.purple.90" variant="trasso_heading_small">
          Tela do terminal
        </Typography>
      </Box>

      <Box
        height="250px"
        border="1px solid transparent"
        borderColor="trasso.gray.30"
        borderRadius="6px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={5}
      >
        <Box>
          <Logo variant="new" width="288px" />
        </Box>
        <Typography variant="trasso_heading_small" color="trasso.purple.80">
          {message}
        </Typography>
      </Box>
    </Box>
  );

  const pinpadActions = (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          websocketUtil.addEvent(payload?.linkedkey, {
            event: 'pdv::close',
          });

          handleClose();
        }}
      >
        Fechar
      </Button>

      {sucessPayment && (
        <LoadingButton
          variant="outlined"
          loading={transactionsState.loading}
          onClick={() => {
            handleClose();
            dispatch(dialogReceipt.action.open(meta));
          }}
        >
          Ver recibo
        </LoadingButton>
      )}
    </>
  );

  return (
    <Dialog open={visible} onClose={() => {}}>
      <Box
        bgcolor="white"
        width={600}
        display="flex"
        flexDirection="column"
        p={4}
        gap={3}
      >
        {pinpadDisplay}
        {pinpadActions}
        {/* {pinpadForm} */}
      </Box>
    </Dialog>
  );
}
