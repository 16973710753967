import fetch from '@giro/shared-utils/fetch.util';

export default async function postLinkService(payload) {
  return fetch(`link`, {
    method: 'POST',
    ms: 'LINK',
    auth: true,
    body: JSON.stringify(payload),
  });
}
