import { ACTION_TYPES } from './auth.constant';

export const setToken = (token) => ({
  type: ACTION_TYPES.TOKEN.SET,
  token,
});

export const unsetToken = (message = '') => ({
  type: ACTION_TYPES.TOKEN.UNSET,
  message,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
