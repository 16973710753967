import fetch from '@giro/shared-utils/fetch.util';
import qs from 'qs';

type Filters = {
  phoneNumber: string;
  historyId: string;
  operatorId: string;
};

export default async function getApiOperatorsPhonenumberProductsService(
  filters: Filters
) {
  const { phoneNumber, ...restFilters } = filters;

  return fetch(
    `operators/${phoneNumber}/products?${qs.stringify(restFilters)}`,
    {
      method: 'GET',
      ms: 'PDV',
      auth: true,
    }
  );
}
