import reducer from './pinpad.reducer';
import * as action from './pinpad.action';
import * as selector from './pinpad.selector';
import * as constant from './pinpad.constant';
import initialState from './pinpad.initialState';
import saga from './pinpad.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
