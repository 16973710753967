import { flow } from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';
import toaster from '@giro/shared-store/toaster';

import postApisAccountsService from '@giro/shared-services/pdv/postApiAccounts.service';

import currentStore from '.';

function* handleFetchStart({ payload }: any) {
  const toasterShow = flow(toaster.action.show, put);

  const [success, body] = yield call(postApisAccountsService, payload);

  if (!success) {
    yield toasterShow({
      message: 'Email ou senha inválido',
      variant: 'error',
    });

    return yield put(currentStore.action.fetchError(body));
  }

  const { token } = body;

  yield put(auth.action.setToken(token));

  return yield put(currentStore.action.fetchEnd(body));
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.FETCH.START,
    handleFetchStart
  );
}

export default {
  watch,
};
