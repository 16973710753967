import { combineReducers } from 'redux';

import dialogs from './dialogs';
import login from './login';
import changePassword from './changePassword';

const reducer = combineReducers({
  [changePassword.name]: changePassword.reducer,
  [login.name]: login.reducer,
  [dialogs.name]: dialogs.reducer,
});

export default reducer;
