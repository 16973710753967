import fetch from '@giro/shared-utils/fetch.util';
import qs from 'qs';
import pickBy from 'lodash/pickBy';

export default async function getApiMonitoringsService(filters) {
  const filtersStringfy = qs.stringify(pickBy(filters));

  const url = ['monitorings', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'MONITORING',
    auth: true,
  });
}
