import { isEqual } from 'lodash';
import { takeLatest, put, select } from 'redux-saga/effects';

import dialogData from '.';

function* handleReset() {
  const state = yield select(dialogData.selector.selectState);

  if (isEqual(dialogData.initialState, state)) {
    return true;
  }

  yield put(dialogData.action.reset());
}

function* watch() {}

export default {
  watch,
};
