import { all } from 'redux-saga/effects';

import dialogs from './dialogs';
import login from './login';
import changePassword from './changePassword';

function* watch() {
  yield all([
    dialogs.saga.watch(),
    login.saga.watch(),
    changePassword.saga.watch(),
  ]);
}

export default {
  watch,
};
