import React from 'react';
import { useFormikContext, Field, Formik, getIn } from 'formik';
import { Box, Dialog, TextField } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { v4 } from 'uuid';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import * as yup from 'yup';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { flow, isEqual } from 'lodash';
import { Paper } from '@mui/material';
import DatePicker from 'react-datepicker';

import TextFieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import ScreenLayout from '@giro-pdv/layouts/Screen.layout';

import TextFieldLabel from '@giro-pdv/components/TextFieldLabel.component';

import TableReportCreaComponent from '../components/TableReportCrea.component';

import transactions from '../store/reports';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const FormSearchCrea = ({ handleResetFilters, loading }) => {
  const [showModalRange, setModalRange] = React.useState(false);
  const { submitForm, errors, setFieldValue, values }: any = useFormikContext();
  const [key, setKey] = React.useState(v4());

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            gap={4}
            key={key}
          >
            <Field name={'dateStart'}>
              {({
                form: { setFieldValue },
                field: { onBlur: fieldOnBlur, ...fieldProp },
              }) => {
                return (
                  <Box
                    position="relative"
                    sx={{
                      '.react-datepicker-popper': {
                        zIndex: 99,
                        inset: 'unset !important',
                        top: '0px !important',
                        right: '8px !important',
                      },
                      '.react-datepicker-wrapper': {
                        display: 'flex',
                      },
                    }}
                  >
                    <DatePicker
                      locale={ptBR}
                      dateFormat="dd/MM/yyyy"
                      selected={fieldProp?.value}
                      onChange={(date) => {
                        setFieldValue('dateStart', date);
                      }}
                      customInput={
                        <TextFieldLabel
                          label={'Data inicial'}
                          fullWidth
                          size="small"
                          {...fieldProp}
                        />
                      }
                    />
                  </Box>
                );
              }}
            </Field>

            <Field name={'dateEnd'}>
              {({
                form: { setFieldValue },
                field: { onBlur: fieldOnBlur, ...fieldProp },
              }) => {
                return (
                  <Box
                    position="relative"
                    sx={{
                      '.react-datepicker-popper': {
                        zIndex: 99,
                        inset: 'unset !important',
                        top: '0px !important',
                        right: '8px !important',
                      },
                      '.react-datepicker-wrapper': {
                        display: 'flex',
                      },
                    }}
                  >
                    <DatePicker
                      locale={ptBR}
                      dateFormat="dd/MM/yyyy"
                      selected={fieldProp?.value}
                      onChange={(date) => {
                        setFieldValue('dateEnd', date);
                      }}
                      customInput={
                        <TextFieldLabel
                          label={'Data final'}
                          fullWidth
                          size="small"
                          {...fieldProp}
                        />
                      }
                    />
                  </Box>
                );
              }}
            </Field>

            <TextFieldLabelFormikComponent
              name="cdCarrinho"
              label="Id carrinho"
              size="small"
            />
          </Box>

          <Box alignSelf="flex-end" gap={2} display="flex" flexDirection="row">
            <Button onClick={handleResetFilters} disabled={loading}>
              Limpar filtros
            </Button>
            <Button
              variant="contained"
              onClick={submitForm}
              disabled={Object.keys(errors).length > 0 || loading}
            >
              Pesquisar
            </Button>
          </Box>
        </Box>
      </Paper>

      <Dialog open={showModalRange} onClose={() => setModalRange(false)}>
        <DateRange
          weekdayDisplayFormat="EEEEEE"
          monthDisplayFormat="MMMM yyyy"
          locale={ptBR}
          showDateDisplay={false}
          months={2}
          direction="horizontal"
          ranges={[
            {
              startDate: values.dateStart || new Date(),
              endDate: values.dateEnd || new Date(),
              key: 'selection',
            },
          ]}
          onChange={({ selection }) => {
            setFieldValue('dateStart', selection.startDate);
            setFieldValue('dateEnd', selection.endDate);

            setKey(v4());
          }}
        />
      </Dialog>
    </>
  );
};

const FormSearchCreaComponent = () => {
  const [key, setKey] = React.useState(v4());

  const validationSchema = yup.object({
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  });

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateFilters: flow(transactions.action.updateFilters, dispatch),
    resetFilters: flow(transactions.action.resetFilters, dispatch),
  };

  const selectorRedux = {
    filters: useSelector(transactions.selector.selectFilters),
    loading: useSelector(transactions.selector.selectLoading),
  };

  const handleUpdateFilters = async (values) => {
    dispatchRedux.updateFilters(values);

    return true;
  };

  const handleResetFilters = () => {
    dispatchRedux.resetFilters();

    if (!isEqual(selectorRedux.filters, transactions.initialState.filters)) {
      dispatchRedux.updateFilters({
        type: 'crea',
      });
    }

    setKey(v4());
  };

  return (
    <Formik
      key={key}
      initialValues={{ ...selectorRedux.filters }}
      enableReinitialize
      onSubmit={handleUpdateFilters}
      validationSchema={validationSchema}
      validateOnMount
    >
      <FormSearchCrea
        handleResetFilters={handleResetFilters}
        loading={selectorRedux.loading}
      />
    </Formik>
  );
};

const CreaScreen = () => {
  return (
    <ScreenLayout title="Relatório de transações - Crea">
      <Box display="flex" flexDirection="column" gap={4}>
        <FormSearchCreaComponent />
      </Box>

      <Box mt={6}>
        <TableReportCreaComponent />
      </Box>
    </ScreenLayout>
  );
};

export default CreaScreen;
