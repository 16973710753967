import { ACTION_TYPES } from './debts.constant';

export const serviceGet = () => ({
  type: ACTION_TYPES.SERVICE.GET,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchSuccess = (payload) => ({
  type: ACTION_TYPES.FETCH.SUCCESS,
  payload,
});

export const fetchError = () => ({
  type: ACTION_TYPES.FETCH.ERROR,
});

export const updateFilters = (filters) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  filters,
});

export const updateSelected = (selected) => ({
  type: ACTION_TYPES.UPDATE.SELECTED,
  selected,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const select = (payload) => ({
  type: ACTION_TYPES.SELECT,
  payload,
});

export const selectAll = () => ({
  type: ACTION_TYPES.SELECT_ALL,
});

export const unselect = (payload) => ({
  type: ACTION_TYPES.UNSELECT,
  payload,
});

export const unselectAll = () => ({
  type: ACTION_TYPES.UNSELECT_ALL,
});
