import { ACTION_TYPES } from './tablePayment.constant';

export const updateAmount = (payload) => ({
  type: ACTION_TYPES.UPDATE.AMOUNT,
  payload,
});

export const updateInstallmentSelected = (payload) => ({
  type: ACTION_TYPES.UPDATE.INSTALLMENT_SELECTED,
  payload,
});

export const updateTypeTax = (payload) => ({
  type: ACTION_TYPES.UPDATE.TYPE_TAX,
  payload,
});

export const updateReturn = (payload) => ({
  type: ACTION_TYPES.UPDATE.RETURN,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
