import reducer from './transactions.reducer';
import * as action from './transactions.action';
import * as selector from './transactions.selector';
import * as constant from './transactions.constant';
import initialState from './transactions.initialState';
import saga from './transactions.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
