import { combineReducers } from 'redux';

import operators from './operators';
import amount from './amount';

const reducer = combineReducers({
  [operators.name]: operators.reducer,
  [amount.name]: amount.reducer,
});

export default reducer;
