const initialState = {
  visible:
    window.localStorage.getItem('menuVisible') === 'true'
      ? true
      : window.localStorage.getItem('menuVisible') === 'false'
      ? false
      : true,
  collapses: [],
};

export default initialState;
