export const NAME = 'menu';

const CONTEXT = 'MENU';

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const UPDATE_COLLAPSES = `${CONTEXT}::UPDATE::COLLAPSES`;

const UPDATE = {
  COLLAPSES: UPDATE_COLLAPSES,
};

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

export const ACTION_TYPES = {
  SET,
  RESET,
  UPDATE,
};
