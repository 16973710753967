import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';

export default async function getApiAppsService(filters) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['apps', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'DEBTS',
  });
}
