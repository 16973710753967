import reducer from './dialogPay.reducer';
import * as action from './dialogPay.action';
import * as selector from './dialogPay.selector';
import * as constant from './dialogPay.constant';
import initialState from './dialogPay.initialState';
import subscriber from './dialogPay.subscriber';
import saga from './dialogPay.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
