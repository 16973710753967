import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useFormikContext, Field, Formik, Form } from 'formik';
import format from 'date-fns/format';

import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import { IconContext } from 'react-icons/lib';
import { MdOutlineAttachMoney } from 'react-icons/md';

import ScreenLayout from '@giro-pdv/layouts/Screen.layout';

import getApiMonitoringsIdService from '@giro/shared-services/monitoring/getApiMonitoringsId.service';
import patchApiMonitoringsIdService from '@giro/shared-services/monitoring/patchApiMonitoringsId.service';
import postApiMonitoringsService from '@giro/shared-services/monitoring/postApiMonitorings.service';

import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';
import FormPlacaRenavamComponent from '@giro-pdv/modules/pdv/components/Forms/FormPlacaRenavam.component';
import FormPlacaDocumentComponent from '@giro-pdv/modules/pdv/components/Forms/FormPlacaDocument.component';
import FormPlateComponent from '@giro-pdv/modules/pdv/components/Forms/FormPlaca.component';
import FormRenavamComponent from '@giro-pdv/modules/pdv/components/Forms/FormRenavam.component';
import TextFieldLabel from '@giro-pdv/components/TextFieldLabel.component';
import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';

import monitorings from '../store/monitorings';
import domain from '@giro-pdv/modules/pdv/store/debts/domain';

const FormSearchTransactions = ({ handleResetFilters, loading, detrans }) => {
  const [showModalRange, setModalRange] = React.useState(false);
  const { submitForm, errors, setFieldValue, values }: any = useFormikContext();
  const [key, setKey] = React.useState(v4());

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
            gap={4}
            key={key}
          >
            <FieldLabelFormikComponent
              name="integration_id"
              label="Detran"
              size="small"
              select
              SelectProps={{
                native: true,
              }}
            >
              <option>Selecione</option>
              {detrans?.map((dt) => (
                <option value={dt.integration_id} key={dt.integration_id}>
                  {dt.detran}
                </option>
              ))}
            </FieldLabelFormikComponent>

            <FieldLabelFormikComponent
              name="renavam"
              label="Renavam"
              size="small"
            />

            <FieldLabelFormikComponent
              name="placa"
              label="Placa"
              size="small"
            />

            <FieldDocumentComponent
              customInput={TextFieldLabel}
              name="document_number"
              label="CPF ou CNPJ"
              size="small"
            />
          </Box>

          <Box alignSelf="flex-end" gap={2} display="flex" flexDirection="row">
            <Button onClick={handleResetFilters} disabled={loading}>
              Limpar filtros
            </Button>
            <Button
              variant="contained"
              onClick={submitForm}
              disabled={Object.keys(errors).length > 0 || loading}
            >
              Pesquisar
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

const FormSearchTransactionsComponent = ({ detrans }) => {
  const [key, setKey] = React.useState(v4());

  const dispatch = useDispatch();

  const handleUpdateFilters = async (values) => {
    dispatch(monitorings.action.updateFilters(values));

    return true;
  };

  const handleResetFilters = () => {
    dispatch(monitorings.action.resetState());
    dispatch(monitorings.action.serviceGet());

    setKey(v4());
  };

  return (
    <Formik
      key={key}
      initialValues={{}}
      enableReinitialize
      onSubmit={handleUpdateFilters}
      validateOnMount
    >
      <FormSearchTransactions
        handleResetFilters={handleResetFilters}
        loading={false}
        detrans={detrans}
      />
    </Formik>
  );
};

const TYPES = {
  PLACARENAVAM: 0,
  RENAVAM: 1,
  DOCUMENTO: 2,
  PLATE: 5,
};

const MonitoringScreen = () => {
  const dispatch = useDispatch();

  const [modalDetails, setModalDetails] = React.useState<any>([false, null]);
  const [modalCreateMonitoring, setModalCreateMonitoring] =
    React.useState<any>(false);
  const [detail, setDetail] = React.useState<any>(null);
  const [loadingDetails, setLoadingDetails] = React.useState<any>(null);

  const dispatchRedux = {
    serviceGet: flow(domain.action.serviceGet, dispatch),
  };

  const domainStore = useSelector(domain.selector.selectState);
  const monitoringsStore = useSelector(monitorings.selector.selectState);

  React.useEffect(() => {
    if (!modalDetails?.[0]) return undefined;

    setLoadingDetails(true);

    getApiMonitoringsIdService(modalDetails[1]).then(([success, data]) => {
      setLoadingDetails(false);
      setDetail(success ? data : null);
    });
  }, [modalDetails]);

  React.useEffect(() => {
    dispatch(monitorings.action.serviceGet());

    if (domainStore?.data?.length > 0) return undefined;

    dispatchRedux.serviceGet();
  }, []);

  const handleFormatToCurrency = (value: number) => {
    return (
      <Typography variant="trasso_body_extra_small">
        {Number(value).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Typography>
    );
  };

  const handleFormatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR');
  };

  const handleGetIntegrationNameById = (integrationId: string) => {
    const integration = domainStore?.data?.find(
      (item) => item.id === integrationId
    );

    const isDetranFilter = ![3, 4].includes(integration?.filter_type);

    if (isDetranFilter) {
      return `Detran ${integration?.description}`;
    }

    return integration?.description;
  };

  const handleGetDocuments = (row, withName = true) => {
    let arr: any = [];
    let component;

    if (row?.integration_id && withName) {
      arr = [...arr, [handleGetIntegrationNameById(row.integration_id), '']];
    }

    if (row?.renavam) {
      arr = [...arr, [row?.renavam, 'Renavam']];
    }

    if (row?.placa) {
      arr = [...arr, [row?.placa, 'Placa']];
    }

    if (row?.document_number) {
      arr = [...arr, [row?.document_number, 'Documento']];
    }

    if (arr.length > 0) {
      component = arr.map(([value, label], idx) => (
        <Box key={label}>
          <Typography key={label} variant="trasso_body_extra_small">
            {!!label && <strong>{label}: </strong>}
            {value}
          </Typography>
        </Box>
      ));
    }

    return component || row?.renavam;
  };

  const handleGetTotal = (row) => {
    const total = [
      row?.total_licensing,
      row?.total_fines,
      row?.total_ipva,
      row?.total_dpvat,
    ].reduce((a, b) => a + b, 0);

    return (
      <Typography variant="trasso_body_extra_small">
        {handleFormatToCurrency(total)}
      </Typography>
    );
  };

  const handleFormatData = (data) => {
    return (
      <Typography variant="trasso_body_extra_small">
        {data ? handleFormatDate(data) : '-'}
      </Typography>
    );
  };

  const handleGetAppByIntegrationId = (integration_id) =>
    domainStore?.data?.find((dd) => dd.id === integration_id);

  const onlyDetrans = domainStore?.data?.filter(
    (a) => a.status === 'ACTIVE' && ![3, 4].some((s) => s === a.filter_type)
  );

  const totalFines = monitoringsStore?.data?.response?.reduce(
    (a, b) => a + b.total_fines,
    0
  );

  const totalIpva = monitoringsStore?.data?.response?.reduce(
    (a, b) => a + b.total_ipva,
    0
  );

  const totalLicensing = monitoringsStore?.data?.response?.reduce(
    (a, b) => a + b.total_licensing,
    0
  );

  const table = (
    <Box position="relative">
      {monitoringsStore?.data?.response?.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1000 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Typography variant="trasso_body_medium" fontWeight={600}>
                    Total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="trasso_body_medium" fontWeight={600}>
                    Multa
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="trasso_body_medium" fontWeight={600}>
                    IPVA
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="trasso_body_medium" fontWeight={600}>
                    Licenciamento
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="trasso_body_medium" fontWeight={600}>
                    Última Consulta
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="trasso_body_medium" fontWeight={600}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {monitoringsStore?.data?.response?.map((row) => (
                <TableRow
                  key={row.integration_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {row.active ? (
                        <Tooltip title="Desativar">
                          <IconButton
                            onClick={() =>
                              patchApiMonitoringsIdService(row.id).then(() => {
                                dispatch(monitorings.action.serviceGet());
                              })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Ativar">
                          <IconButton
                            onClick={() =>
                              patchApiMonitoringsIdService(row.id).then(() => {
                                dispatch(monitorings.action.serviceGet());
                              })
                            }
                          >
                            <RestoreFromTrashIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{handleGetDocuments(row)}</TableCell>
                  <TableCell>{handleGetTotal(row)}</TableCell>
                  <TableCell>
                    {handleFormatToCurrency(row?.total_fines)}
                  </TableCell>
                  <TableCell>
                    {handleFormatToCurrency(row?.total_ipva)}
                  </TableCell>
                  <TableCell>
                    {handleFormatToCurrency(row?.total_licensing)}
                  </TableCell>
                  <TableCell>
                    {handleFormatData(row.last_monitor_date)}
                  </TableCell>
                  <TableCell>
                    <Typography variant="trasso_body_extra_small">
                      {row.active ? 'Ativo' : 'Não ativo'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setModalDetails([true, row.id]);
                      }}
                    >
                      Detalhes
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {monitoringsStore?.data?.response?.length === 0 && (
        <Box>
          <Typography>Você ainda não tem nenhum monitoramento</Typography>
        </Box>
      )}

      {monitoringsStore.loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={2}
          bgcolor="#ffffffba"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  const dialogDetails = (
    <Dialog
      open={modalDetails?.[0]}
      scroll="body"
      onClose={() => {
        setModalDetails([false, null]);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loadingDetails === false && (
        <Box display="flex" flexDirection="column" p={2} gap={1.5}>
          <Typography>{handleGetDocuments(detail)}</Typography>
          <Divider />

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            rowGap={0.5}
            columnGap={3}
          >
            <Box display="flex" gap={0.5}>
              <Typography variant="trasso_body_medium" fontWeight={600}>
                Licenciamento:
              </Typography>
              <Typography variant="trasso_body_medium">
                {handleFormatToCurrency(detail?.total_licensing)}
              </Typography>
            </Box>
            <Box display="flex" gap={0.5}>
              <Typography variant="trasso_body_medium" fontWeight={600}>
                Multas:
              </Typography>
              <Typography variant="trasso_body_medium">
                {handleFormatToCurrency(detail?.total_fines)}
              </Typography>
            </Box>
            <Box display="flex" gap={0.5}>
              <Typography variant="trasso_body_medium" fontWeight={600}>
                IPVA:
              </Typography>
              <Typography variant="trasso_body_medium">
                {handleFormatToCurrency(detail?.total_ipva)}
              </Typography>
            </Box>
            <Box display="flex" gap={0.5}>
              <Typography variant="trasso_body_medium" fontWeight={600}>
                DPVAT:
              </Typography>
              <Typography variant="trasso_body_medium">
                {handleFormatToCurrency(detail?.total_dpvat)}
              </Typography>
            </Box>
            <Box display="flex" gap={0.5}>
              <Typography variant="trasso_body_medium" fontWeight={600}>
                Total:
              </Typography>
              <Typography variant="trasso_body_medium">
                {handleGetTotal(detail)}
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="trasso_body_medium" fontWeight={600}>
              Débitos ativos ({detail?.debts_count})
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              {detail?.debts_detail?.map((item) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={0.5}
                  key={item?.reference}
                  bgcolor="#f4f4f4"
                  border="1px solid transparent"
                  borderColor="#e0e0e0"
                  p={1}
                  borderRadius={2}
                >
                  <Typography variant="trasso_body_medium">
                    <strong>Descrição:</strong> {item?.description}
                  </Typography>
                  <Typography variant="trasso_body_medium">
                    <strong>Valor:</strong>{' '}
                    {handleFormatToCurrency(item?.amount)}
                  </Typography>
                  <Typography variant="trasso_body_medium">
                    <strong>Vencimento:</strong>{' '}
                    {handleFormatDate(item?.due_date)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Divider />

          <Button
            onClick={() => {
              setModalDetails([false, null]);
            }}
            variant="contained"
          >
            Fechar
          </Button>
        </Box>
      )}

      {(loadingDetails === true || loadingDetails === null) && (
        <Box
          width={500}
          height={500}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );

  const dialogCreateMonitoring = (
    <Dialog
      open={modalCreateMonitoring}
      scroll="body"
      onClose={() => {
        setModalCreateMonitoring(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          integration_id: '',
        }}
        onSubmit={async (values) => {
          const [success] = await postApiMonitoringsService(values);

          if (success) {
            setModalCreateMonitoring(false);
            dispatch(monitorings.action.serviceGet());
          }

          return true;
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              gap={3}
              width={600}
            >
              <FieldLabelFormikComponent
                name="integration_id"
                label="Detran"
                select
                size="small"
              >
                {onlyDetrans?.map((od) => (
                  <MenuItem value={od.id} key={od.id}>
                    {od?.description}
                  </MenuItem>
                ))}
              </FieldLabelFormikComponent>

              {handleGetAppByIntegrationId(values?.integration_id)
                ?.filter_type === TYPES.PLACARENAVAM && (
                <FormPlacaRenavamComponent />
              )}

              {handleGetAppByIntegrationId(values?.integration_id)
                ?.filter_type === TYPES.DOCUMENTO && (
                <FormPlacaDocumentComponent />
              )}

              {handleGetAppByIntegrationId(values?.integration_id)
                ?.filter_type === TYPES.RENAVAM && (
                <FormRenavamComponent key={values?.integration_id} />
              )}

              {handleGetAppByIntegrationId(values?.integration_id)
                ?.filter_type === TYPES.PLATE && (
                <FormPlateComponent key={values?.integration_id} />
              )}

              <Divider />

              <Box display="flex" flexDirection="column" gap={1.5}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                >
                  Criar monitoramento
                </LoadingButton>
                <Button
                  onClick={() => {
                    setModalCreateMonitoring(false);
                  }}
                  variant="outlined"
                >
                  Fechar
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

  const totals = (
    <Box display="flex" gap={2}>
      <Box
        sx={{
          boxShadow: 1,
          display: 'inline-flex',
          justifyContent: 'flex-start',
          minWidth: '250px',
        }}
        display="grid"
        gridTemplateColumns="1fr 1fr"
      >
        <Box
          bgcolor="#022e59"
          sx={{
            p: 3,
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <IconContext.Provider value={{ color: 'white', size: '25px' }}>
            <MdOutlineAttachMoney />
          </IconContext.Provider>
        </Box>
        <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
          <Typography
            variant="trasso_body_medium"
            color="grey.700"
            fontWeight={600}
          >
            {Number(totalFines || 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
          <Typography variant="trasso_heading_small" color="grey.500">
            Multas
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          boxShadow: 1,
          display: 'inline-flex',
          justifyContent: 'flex-start',
          minWidth: '250px',
        }}
        display="grid"
        gridTemplateColumns="1fr 1fr"
      >
        <Box
          bgcolor="#022e59"
          sx={{
            p: 3,
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <IconContext.Provider value={{ color: 'white', size: '25px' }}>
            <MdOutlineAttachMoney />
          </IconContext.Provider>
        </Box>
        <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
          <Typography
            variant="trasso_body_medium"
            color="grey.700"
            fontWeight={600}
          >
            {Number(totalIpva || 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
          <Typography variant="trasso_heading_small" color="grey.500">
            IPVA
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          boxShadow: 1,
          display: 'inline-flex',
          justifyContent: 'flex-start',
          minWidth: '250px',
        }}
        display="grid"
        gridTemplateColumns="1fr 1fr"
      >
        <Box
          bgcolor="#022e59"
          sx={{
            p: 3,
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <IconContext.Provider value={{ color: 'white', size: '25px' }}>
            <MdOutlineAttachMoney />
          </IconContext.Provider>
        </Box>
        <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
          <Typography
            variant="trasso_body_medium"
            color="grey.700"
            fontWeight={600}
          >
            {Number(totalLicensing || 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
          <Typography variant="trasso_heading_small" color="grey.500">
            Licenciamento
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const imgPartner = (
    <Box alignSelf="center">
      <img src="/logo-sao-martinho.png" width={200} />
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="trasso_heading_medium" color="trasso.pink">
          Monitoramento
        </Typography>

        {imgPartner}

        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            onClick={() => setModalCreateMonitoring(true)}
          >
            Novo Monitoramento
          </Button>
        </Box>
      </Box>

      <Box mb={4} mt={4} height={2} bgcolor="#E4DAEB" />

      <Box>
        <Box
          display="flex"
          flexDirection="column"
          minHeight="calc(100vh - 480px)"
          gap={3}
        >
          <Box display="flex" flexDirection="column" gap={4}>
            <FormSearchTransactionsComponent
              detrans={monitoringsStore?.data?.detrans}
            />
          </Box>
          {totals}
          {table}
          {dialogDetails}
          {dialogCreateMonitoring}
        </Box>
      </Box>
    </Box>
  );
};

export default MonitoringScreen;
