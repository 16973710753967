import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { useLocation, useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import RefreshIcon from '@mui/icons-material/Refresh';

import useIsCreaHook from '@giro-pdv/hooks/useIsCrea.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import useIsMobile from '@giro-pdv/hooks/useIsMobile.hook';
import useIsTotemHook from '@giro-pdv/hooks/useTotem.hook';

import Logo from '@giro/shared-components/Logo.component';

import ROUTES, { NAMESPACES } from '@giro-pdv/constants/routes.constant';
import segmentNormalizeConstant from '@giro-pdv/constants/segmentNormalize.constant';

import clients from '@giro-pdv/modules/pdv/store/clients';
import sync from '@giro-pdv/modules/pdv/store/sync';

const menuSidebarConstant = [
  {
    label: 'DETRANs',
    route: ROUTES.PDV.DETRAN,
    segment: segmentNormalizeConstant.DETRAN,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#shield" />
      </svg>
    ),
  },
  {
    label: 'Totem',
    route: ROUTES.PDV.TOTEM,
    segment: segmentNormalizeConstant.TOTEM,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#monitor" />
      </svg>
    ),
  },
  {
    label: 'Boletos',
    route: ROUTES.PDV.BILLET,
    segment: segmentNormalizeConstant.BOLETO,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#align-justify" />
      </svg>
    ),
  },
  {
    label: 'Financiamento',
    route: ROUTES.PDV.FINANCING,
    segment: segmentNormalizeConstant.FINANCIAMENTO,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#divide" />
      </svg>
    ),
  },
  {
    label: 'Monitoramento',
    route: ROUTES.MONITORING.ROOT,
    segment: segmentNormalizeConstant.MONITORING,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#monitor" />
      </svg>
    ),
  },
  // {
  //   label: 'PIX',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: 'Seguros',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: 'Crédito',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: 'Agenda de recebíveis',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  {
    label: 'Recarga de celular',
    route: ROUTES.PDV.RECHARGE,
    segment: segmentNormalizeConstant.RECHARGE,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#smartphone" />
      </svg>
    ),
  },
];

const menu = ({ isTotem, isCrea, menuSub, pathname, segment }) =>
  [
    !isTotem &&
      !isCrea && {
        label: 'PDV',
        module:
          !menuSub.some((ms) => ms.route === pathname) &&
          NAMESPACES.PDV.substring(1),
        route: ROUTES.PDV.ROOT,
        icon: (
          <svg
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <use href="/feather-sprite.svg#dollar-sign" />
          </svg>
        ),
      },
    !isTotem && {
      label: 'Relatórios',
      module: NAMESPACES.REPORTS.substring(1),
      route: ROUTES.REPORTS.ROOT,
      icon: (
        <svg
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#clipboard" />
        </svg>
      ),
      submenu: [
        !isCrea && {
          label: 'EC',
          route: ROUTES.REPORTS.EC,
          icon: (
            <svg
              width="16"
              height="16"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <use href="/feather-sprite.svg#clipboard" />
            </svg>
          ),
        },
        !isCrea &&
          segment?.includes(segmentNormalizeConstant.FINANCIAMENTO) && {
            label: 'Financiamento',
            route: ROUTES.REPORTS.FINANCING,
            icon: (
              <svg
                width="16"
                height="16"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <use href="/feather-sprite.svg#clipboard" />
              </svg>
            ),
          },
        isCrea && {
          label: 'Crea',
          route: ROUTES.REPORTS.CREA,
          icon: (
            <svg
              width="16"
              height="16"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <use href="/feather-sprite.svg#clipboard" />
            </svg>
          ),
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);

const menuPDV = (segment) => [
  {
    label: 'Cartório',
    route: ROUTES.PDV.CARTORIO,
    visible: segment.includes(segmentNormalizeConstant.CARTORIO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#home" />
      </svg>
    ),
  },
  {
    label: 'Sobre',
    module: NAMESPACES.PDV.substring(1),
    visible: segment.includes(segmentNormalizeConstant.CARTORIO),
    route: ROUTES.PDV.ABOUT,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#info" />
      </svg>
    ),
  },
  {
    label: 'Despachante',
    route: ROUTES.PDV.AGENT,
    visible: segment.includes(segmentNormalizeConstant.DESPACHANTE),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
  {
    label: 'Veículo',
    route: ROUTES.PDV.VEHICLE,
    visible: segment.includes(segmentNormalizeConstant.VEICULOS),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#truck" />
      </svg>
    ),
  },
  {
    label: 'Geral',
    route: ROUTES.PDV.GERAL,
    visible: segment.includes(segmentNormalizeConstant.GERAL),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#sidebar" />
      </svg>
    ),
  },
  {
    label: 'Educação',
    route: ROUTES.PDV.EDUCACAO,
    visible: segment.includes(segmentNormalizeConstant.EDUCACAO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#book-open" />
      </svg>
    ),
  },
  {
    label: 'Pátio',
    route: ROUTES.PDV.VEHICLE,
    visible: segment.includes(segmentNormalizeConstant.PATIO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#truck" />
      </svg>
    ),
  },
  {
    label: 'Assessoria',
    route: ROUTES.PDV.ASSESSORIA,
    visible: segment.includes(segmentNormalizeConstant.ACESSORIA),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#hash" />
      </svg>
    ),
  },
  {
    label: 'Cemitério',
    route: ROUTES.PDV.CEMITERIO,
    visible: segment.includes(segmentNormalizeConstant.CEMITERIO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#award" />
      </svg>
    ),
  },
];

const menuReports = ({ isCrea, segment }) => [
  {
    label: 'EC',
    route: ROUTES.REPORTS.EC,
    visible: !isCrea,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
  {
    label: 'Financiamento',
    route: ROUTES.REPORTS.FINANCING,
    visible:
      !isCrea && segment?.includes(segmentNormalizeConstant.FINANCIAMENTO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
  {
    label: 'Crea',
    route: ROUTES.REPORTS.CREA,
    visible: isCrea,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
];

const HeaderComponent = ({ handleToggleSide = () => {}, sideOpen }) => {
  const isFirstRender = React.useRef(true);

  const [menuCurrentRender, setMenuCurrentRender] = React.useState('primary');

  const dispatch = useDispatch();

  const isMobile = useIsMobile();
  const { user } = useAuthHook();
  const { pathname } = useLocation();
  const isCrea = useIsCreaHook();
  const isTotem = useIsTotemHook();
  const history = useHistory();

  const selectorClientsPdv = useSelector(clients.selector.selectState);
  const selectorSyncPdv = useSelector(sync.selector.selectState);

  const isSubPdv = menuSidebarConstant.map((ms) => ms.route).includes(pathname);
  const segment = user?.user?.codes?.segment;
  const currentModule = isSubPdv ? 'default' : pathname.split('/')[1];
  const menuToRender = {
    default: flow(() => {}),
    pdv: flow(() => user?.user?.codes?.segment, menuPDV),
    reports: flow(
      () => ({ isCrea, segment: user?.user?.codes?.segment }),
      menuReports
    ),
  };
  const menuSecondary =
    (menuToRender[currentModule] || menuToRender.default)() || [];
  const menuSecondaryVisible = menuSecondary?.filter((e) => e.visible);
  const menuPrimaryConcat = menuSidebarConstant.filter((el) =>
    segment?.includes(el.segment)
  );
  const menuPrimary = menu({
    isTotem,
    isCrea,
    menuSub: menuPrimaryConcat,
    pathname,
    segment: user?.user?.codes?.segment,
  }).concat(menuPrimaryConcat as any);

  const menuRender =
    menuCurrentRender === 'primary' ? menuPrimary : menuSecondaryVisible;

  // React.useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //     return undefined;
  //   }

  //   if (menuSecondaryVisible.length > 0 && !isFirstRender.current) {
  //     setMenuCurrentRender('secondary');
  //   }
  // }, [pathname]);

  const nameHtml = (
    <Typography color="white" align="right" variant="subtitle1">
      {user?.user?.name}
    </Typography>
  );

  return (
    <Box className="header-container" display="flex" flexDirection="column">
      <Box bgcolor="trasso.purple.90">
        <Container maxWidth="lg">
          <Box
            display="grid"
            gridTemplateColumns={'1fr 1fr 1fr'}
            alignItems="center"
            height="96px"
          >
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              width="100%"
              justifyContent={isMobile ? 'space-between' : undefined}
            >
              <Logo variant="pdv" width="190" />
            </Box>
            <Typography
              align="center"
              color="secondary"
              variant="header_sm"
              fontWeight={400}
            >
              {selectorClientsPdv?.payload?.nmFantasia}
            </Typography>
            <Box display="flex" justifyContent="end">
              {!selectorSyncPdv?.payload?.linkedkey && nameHtml}

              {selectorSyncPdv?.payload?.linkedkey && (
                <Tooltip
                  arrow
                  title={
                    <Box display="flex" flexDirection="column">
                      <Typography
                        align="center"
                        variant="trasso_button"
                        fontWeight={500}
                      >
                        Código de sincronização
                      </Typography>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography>
                          <strong>{selectorSyncPdv?.payload?.linkedkey}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  }
                >
                  {nameHtml}
                </Tooltip>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <Box bgcolor="trasso.gray.20">
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            bgcolor="trasso.gray.20"
            alignItems="center"
            py={2}
          >
            <Box display="flex" gap={3}>
              {menuRender?.map((m: any, i) => (
                <Box
                  key={`idx-${i}`}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                  minWidth={100}
                  sx={{
                    cursor: 'pointer',
                    svg: {
                      color: 'trasso.pink',
                    },
                  }}
                  onClick={() => {
                    history.push(m.route);
                  }}
                >
                  <Box
                    width={50}
                    height={50}
                    borderRadius={3}
                    sx={{
                      background:
                        'linear-gradient(0deg, #E5AEFF 0%, #E8BCFF 34.87%, #EEE1FF 100%)',
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {m?.icon}
                  </Box>

                  <Typography color="trasso.purple.80" variant="body">
                    {m?.label}
                  </Typography>
                </Box>
              ))}
            </Box>

            {menuCurrentRender !== 'primary' && (
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: '12px',
                    border: '1px trasso.grey.40',
                  }}
                  onClick={() => {
                    setMenuCurrentRender('primary');
                  }}
                >
                  Voltar
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HeaderComponent;
