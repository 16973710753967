import reducer from './router.reducer';
import saga from './router.saga';
import * as action from './router.action';
import * as selector from './router.selector';
import * as constant from './router.constant';
import initialState from './router.initialState';
import subscriber from './router.subscriber';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  name: constant.NAME,
  subscriber,
};
