import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';

import ButtonPayActionsComponent from '../components/ButtonPayActions.component';
import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import TextFieldLabelComponent from '@giro-pdv/components/TextFieldLabel.component';

import PDVLayout from '../layouts/PDV.layout';

import UpdateAmountComponent from '../components/UpdateAmount.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const FormGeral = () => {
  const dispatch = useDispatch();
  const { user } = useAuthHook();

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
  };

  const initialValues = {
    client: null,
    service: null,
    total: null,
    segment: '16',
    att_password: null,
    type_tax: 'client',
  };

  const onSubmit = () => {};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <UpdateAmountComponent />
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <FieldLabelFormikComponent
                  size="small"
                  name="client"
                  label="Identificação do cliente"
                />

                <FieldLabelFormikComponent
                  size="small"
                  name="att_password"
                  label="Senha do atendimento"
                />

                <FieldCurrencyComponentDigits
                  customInput={TextFieldLabelComponent}
                  size="small"
                  name="service"
                  label="Serviços"
                  onChange={(val) => setFieldValue('total', Number(val))}
                />

                <FieldCurrencyComponent
                  customInput={TextFieldLabelComponent}
                  size="small"
                  label="Total"
                  name="total"
                  disabled
                />
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de taxa
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="portador"
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type_tax', value);

                        dispatchRedux.updateTypeTax(value);

                        if (value === 'ec') {
                          dispatchRedux.updateReturn(0);
                        }
                      }}
                    >
                      <FormControlLabel
                        value="client"
                        checked={values.type_tax === 'client'}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'trasso.pink',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="trasso_body_medium"
                            color="trasso.purple.100"
                          >
                            Portador
                          </Typography>
                        }
                      />
                      {user?.user?.codes?.ec === 1 && (
                        <FormControlLabel
                          value="ec"
                          checked={values.type_tax === 'ec'}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: 'trasso.pink',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="trasso.purple.100"
                            >
                              EC
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                <ButtonPayActionsComponent
                  loading={selectorRedux.transactionsLoading}
                  onClickPay={() =>
                    dispatchRedux.transactionsServicePost({
                      ...values,
                    })
                  }
                  disabledPay={
                    selectorRedux.transactionsLoading ||
                    selectorRedux.installmentSelected === null ||
                    Number((values as any).total) === 0
                  }
                  disabledSend={Number((values as any).total) === 0}
                />
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const CartorioScreen = () => {
  return (
    <PDVLayout>
      <FormGeral />
    </PDVLayout>
  );
};

export default CartorioScreen;
