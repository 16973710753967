import { all } from 'redux-saga/effects';

import domain from './domain';
import debts from './debts';

function* watch() {
  yield all([domain.saga.watch(), debts.saga.watch()]);
}

export default {
  watch,
};
