import { createReducer } from '@reduxjs/toolkit';

import initialState from './pinpad.initialState';
import { ACTION_TYPES } from './pinpad.constant';

const handleResetState = (state) => ({
  ...initialState,
  present: state.present,
  status: state.status,
});

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
};

const handleFetchSuccess = (state, action) => {
  const force = action.force;

  state.loading = false;
  state.data = force ? action.payload : [...(state.data || []), action.payload];
};

const handleUpdateSession = (state, action) => {
  state.session = {
    ...state.session,
    ...action.payload,
  };
};

const handleUpdateMessage = (state, action) => {
  state.message = action.payload;
};

const handleUpdateTef = (state, action) => {
  state.tef = action.payload;
};

const handleUpdateStatus = (state, action) => {
  state.status = action.payload;
};

const handleUpdatePresent = (state, action) => {
  state.present = action.payload;
};

const handleUpdateTransactionStatus = (state, action) => {
  state.transaction_status = action.payload;
};

export default createReducer(initialState, {
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.FETCH.SUCCESS]: handleFetchSuccess,
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.UPDATE.SESSION]: handleUpdateSession,
  [ACTION_TYPES.UPDATE.MESSAGE]: handleUpdateMessage,
  [ACTION_TYPES.UPDATE.TEF]: handleUpdateTef,
  [ACTION_TYPES.UPDATE.STATUS]: handleUpdateStatus,
  [ACTION_TYPES.UPDATE.PRESENT]: handleUpdatePresent,
  [ACTION_TYPES.UPDATE.TRANSACTION_STATUS]: handleUpdateTransactionStatus,
});
