import React from 'react';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { Field } from 'formik';

import TextFieldLabelKeyboardComponent from '@giro-pdv/components/TextFieldLabelKeyboard.component';

import FieldLabelFormik from '@giro-pdv/components/FieldLabelFormik.component';

import messages from '@giro/shared-constants/messagesSchema.constant';

export const validationSchema = Yup.object({
  placa: Yup.string()
    .required(messages.REQUIRED)
    .min(7, messages.MIN)
    .max(7, messages.MAX)
    .matches(
      /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/,
      'Você precisa digitar uma placa válida'
    ),
  renavam: Yup.string().required(messages.REQUIRED),
});

const FormPlacaComponent = (props) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" {...props} gap={4}>
      <TextFieldLabelKeyboardComponent
        name="placa"
        label="Placa"
        size="small"
      />
    </Box>
  );
};

export default FormPlacaComponent;
