import React from 'react';
import { useFormikContext, Field, Formik } from 'formik';
import { Box, Dialog } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { v4 } from 'uuid';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import * as yup from 'yup';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { flow, isEqual } from 'lodash';
import { Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';

import ScreenLayout from '@giro-pdv/layouts/Screen.layout';

import TextFieldLabel from '@giro-pdv/components/TextFieldLabel.component';

import TableReportTransactionsComponent from '../components/TableReportFinancing.component';

import ROUTES from '@giro-pdv/constants/routes.constant';

import TextFieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import transactions from '../store/reports';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const FormSearchTransactions = ({ handleResetFilters, loading }) => {
  const [showModalRange, setModalRange] = React.useState(false);
  const { submitForm, errors, setFieldValue, values }: any = useFormikContext();
  const [key, setKey] = React.useState(v4());

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4} key={key}>
            <Field
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'trasso.purple.100',
                  '& fieldset': {
                    borderColor: 'trasso.gray.40',
                  },
                },
              }}
              component={TextFieldLabel}
              name="dateStart"
              label="Data inicial"
              size="small"
              onClick={() => setModalRange(true)}
              inputProps={{
                readOnly: true,
              }}
              value={
                values?.dateStart &&
                format(new Date(values?.dateStart), 'dd/MM/yyyy')
              }
            />
            <Field
              component={TextFieldLabel}
              name="dateEnd"
              label="Data final"
              size="small"
              onClick={() => setModalRange(true)}
              inputProps={{
                readOnly: true,
              }}
              value={
                values?.dateEnd &&
                format(new Date(values?.dateEnd), 'dd/MM/yyyy')
              }
            />
          </Box>

          <Box alignSelf="flex-end" gap={2} display="flex" flexDirection="row">
            <Button onClick={handleResetFilters} disabled={loading}>
              Limpar filtros
            </Button>
            <Button
              variant="contained"
              onClick={submitForm}
              disabled={Object.keys(errors).length > 0 || loading}
            >
              Pesquisar
            </Button>
          </Box>
        </Box>
      </Paper>

      <Dialog open={showModalRange} onClose={() => setModalRange(false)}>
        <DateRange
          weekdayDisplayFormat="EEEEEE"
          monthDisplayFormat="MMMM yyyy"
          locale={ptBR}
          showDateDisplay={false}
          months={2}
          direction="horizontal"
          ranges={[
            {
              startDate: values.dateStart || new Date(),
              endDate: values.dateEnd || new Date(),
              key: 'selection',
            },
          ]}
          onChange={({ selection }) => {
            setFieldValue('dateStart', selection.startDate);
            setFieldValue('dateEnd', selection.endDate);

            setKey(v4());
          }}
        />
      </Dialog>
    </>
  );
};

const FormSearchTransactionsComponent = () => {
  const [key, setKey] = React.useState(v4());

  const validationSchema = yup.object({
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  });

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateFilters: flow(transactions.action.updateFilters, dispatch),
    resetFilters: flow(transactions.action.resetFilters, dispatch),
  };

  const selectorRedux = {
    filters: useSelector(transactions.selector.selectFilters),
    loading: useSelector(transactions.selector.selectLoading),
  };

  const handleUpdateFilters = async (values) => {
    dispatchRedux.updateFilters(values);

    return true;
  };

  const handleResetFilters = () => {
    dispatchRedux.resetFilters();

    if (!isEqual(selectorRedux.filters, transactions.initialState.filters)) {
      dispatchRedux.updateFilters({
        type: 'ec',
      });
    }

    setKey(v4());
  };

  return (
    <Formik
      key={key}
      initialValues={{ ...selectorRedux.filters }}
      enableReinitialize
      onSubmit={handleUpdateFilters}
      validationSchema={validationSchema}
      validateOnMount
    >
      <FormSearchTransactions
        handleResetFilters={handleResetFilters}
        loading={selectorRedux.loading}
      />
    </Formik>
  );
};

const ReportsFinancingScreen = () => {
  const history = useHistory();

  return (
    <ScreenLayout
      title="Relatório de simulações"
      cta={
        <Button
          onClick={() => history.push(ROUTES.PDV.FINANCING)}
          variant="contained"
        >
          Nova simulação
        </Button>
      }
    >
      <TableReportTransactionsComponent />
    </ScreenLayout>
  );
};

export default ReportsFinancingScreen;
