import reducer from './pdv.reducer';
import * as selector from './pdv.selector';
import * as constant from './pdv.constant';
import subscriber from './pdv.subscriber';
import saga from './pdv.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
