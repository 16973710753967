import { flow } from 'lodash';

import { NAME } from './auth.constant';

export const selectState = (state) => state[NAME];

const selectSignedInKey = (state) => state?.signedIn;
const selectTokenKey = (state) => state.token;
const selectUserKey = (state) => state.user;

export const selectSignedIn = flow(selectState, selectSignedInKey);
export const selectToken = flow(selectState, selectTokenKey);
export const selectUser = flow(selectState, selectUserKey);
