import { all } from 'redux-saga/effects';

import transactions from './receipt';

function* watch() {
  yield all([transactions.saga.watch()]);
}

export default {
  watch,
};
