import { ACTION_TYPES } from './pinpad.constant';

export const updateTransactionStatus = (payload) => ({
  type: ACTION_TYPES.UPDATE.TRANSACTION_STATUS,
  payload,
});

export const updateSession = (payload) => ({
  type: ACTION_TYPES.UPDATE.SESSION,
  payload,
});

export const updateStatus = (status) => ({
  type: ACTION_TYPES.UPDATE.STATUS,
  payload: status,
});

export const updatePresent = (payload) => ({
  type: ACTION_TYPES.UPDATE.PRESENT,
  payload,
});

export const updateTef = (payload) => ({
  type: ACTION_TYPES.UPDATE.TEF,
  payload,
});

export const updateMessage = (payload) => ({
  type: ACTION_TYPES.UPDATE.MESSAGE,
  payload,
});

export const servicePostStart = () => ({
  type: ACTION_TYPES.SERVICE.POST_START,
});

export const servicePostContinue = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST_CONTINUE,
  payload,
});

export const servicePostFinish = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST_FINISH,
  payload,
});

export const servicePostIsPresent = () => ({
  type: ACTION_TYPES.SERVICE.POST_IS_PRESENT,
});

export const servicePostNewSession = () => ({
  type: ACTION_TYPES.SERVICE.POST_NEW_SESSION,
});

export const servicePostDestroySession = () => ({
  type: ACTION_TYPES.SERVICE.POST_DESTROY_SESSION,
});

export const servicePostMessage = () => ({
  type: ACTION_TYPES.SERVICE.POST_MESSAGE,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const fetchSuccess = (payload, force = false) => ({
  type: ACTION_TYPES.FETCH.SUCCESS,
  payload,
  force,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
