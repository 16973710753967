import reducer from './dialogReceipt.reducer';
import * as action from './dialogReceipt.action';
import * as selector from './dialogReceipt.selector';
import * as constant from './dialogReceipt.constant';
import initialState from './dialogReceipt.initialState';
import saga from './dialogReceipt.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
