import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { flow } from 'lodash';

import toaster from '@giro/shared-store/toaster';

const useNotification = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const selectorToasterRedux = {
    message: useSelector(toaster.selector.selectMessage),
    variant: useSelector(toaster.selector.selectVariant),
    configs: useSelector(toaster.selector.selectConfigs),
  };

  const dispatchToasterRedux = {
    reset: flow(toaster.action.reset, dispatch),
  };

  const handleNotification = useCallback(
    (
      message: string,
      variant: 'success' | 'error' | 'info' | 'warning' | 'default',
      opts = {}
    ) =>
      enqueueSnackbar(message, {
        onExited: () => dispatchToasterRedux.reset(),
        variant,
        autoHideDuration: variant === 'error' ? 6000 : 3000,
        action: (key: any) => (
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <span style={{ color: 'white' }}>Fechar</span>
          </Button>
        ),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        ...opts,
      }),
    [enqueueSnackbar, closeSnackbar]
  );

  useEffect(() => {
    if (selectorToasterRedux.message) {
      handleNotification(
        selectorToasterRedux.message,
        selectorToasterRedux.variant,
        selectorToasterRedux.configs
      );
    }
  }, [
    selectorToasterRedux.message,
    selectorToasterRedux.variant,
    selectorToasterRedux.configs,
  ]);
};

export default useNotification;
