import { flow } from 'lodash';

import { NAME } from './pinpad.constant';

import { selectState as selectStatePDV } from '../pdv.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectDataKey = (state) => state.data;
const selectSessionKey = (state) => state.session;
const selectMessageKey = (state) => state.message;
const selectTefKey = (state) => state.tef;
const selectErrorKey = (state) => state.error;
const selectStatusKey = (state) => state.status;
const selectPresentKey = (state) => state.present;

export const selectState = flow(selectStatePDV, selectStateKey);
export const selectPresent = flow(selectState, selectPresentKey);
export const selectStatus = flow(selectState, selectStatusKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectTef = flow(selectState, selectTefKey);
export const selectMessage = flow(selectState, selectMessageKey);
export const selectSession = flow(selectState, selectSessionKey);
export const selectData = flow(selectState, selectDataKey);
export const selectLoading = flow(selectState, selectLoadingKey);
