const segmentNormalizeConstant = {
  DESPACHANTE: 2,
  VEICULOS: 3,
  GERAL: 4,
  CEMITERIO: 15,
  EDUCACAO: 9,
  PATIO: 10,
  ACESSORIA: 11,
  BOLETO: 5,
  DETRAN: 7,
  FINANCIAMENTO: 12,
  RECHARGE: 13,
  TOTEM: 14,
  CARTORIO: 16,
  MONITORING: 17,
};

export const segmentNormalizeInvertConstant = Object.keys(
  segmentNormalizeConstant
).reduce((acc, key) => {
  return {
    ...acc,
    [segmentNormalizeConstant[key]]: key,
  };
}, {});

export default segmentNormalizeConstant;
