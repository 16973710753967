import { flow } from 'lodash';

import { NAME } from './router.constant';

const selectStateKey = (state) => state[NAME];
const selectPathnameKey = (state) => state.pathname;
const selectSearchKey = (state) => state.search;
const selectHashKey = (state) => state.hash;

export const selectState = flow(selectStateKey);
export const selectPathname = flow(selectState, selectPathnameKey);
export const selectSearch = flow(selectState, selectSearchKey);
export const selectHash = flow(selectState, selectHashKey);
