import { createReducer } from '@reduxjs/toolkit';

import initialState from './toaster.initialState';
import { ACTION_TYPES } from './toaster.constant';

const handleShow = (state, action) => {
  state.message =
    action?.payload?.message || 'Aconteceu um erro, tente novamente mais tarde';
  state.variant = action.payload.variant;
  state.configs = action?.payload?.configs || {};
};

const handleReset = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.SHOW]: handleShow,
  [ACTION_TYPES.RESET]: handleReset,
});
