import reducer from './auth.reducer';
import saga from './auth.saga';
import * as action from './auth.action';
import * as selector from './auth.selector';
import * as constant from './auth.constant';
import initialState from './auth.initialState';
import subscriber from './auth.subscriber';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  name: constant.NAME,
  subscriber,
};
