import mock from '@giro-pdv/mocks/state.mock.json';

const initialState = {
  visible: null,
  payload: null,
  loading: null,
};

// export default mock.pdv.dialogs.dialogPay;
export default initialState;
