export const NAME = 'dialogReceipt';

const CONTEXT = 'DIALOGS::RECEIPT';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

const SERVICE = {
  GET: SERVICE_GET,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  SET,
  RESET,
  SERVICE,
  FETCH,
};
