import isEmpty from 'lodash/isEmpty';

import dialogData from '.';

let nextState = {};
let prevState = {};

const isStateEmpty = () => isEmpty(prevState) || isEmpty(nextState);

const resetWhenClose = (dispatch) => {
  if (isStateEmpty()) return null;

  const shouldVisibleIsFalse =
    dialogData.selector.selectVisible(nextState) === false;

  if (shouldVisibleIsFalse) {
    dispatch(dialogData.action.reset());
  }
};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  resetWhenClose(dispatch);

  prevState = nextState;
};

export default {
  subscribe,
};
