import reducer from './domain.reducer';
import * as action from './domain.action';
import * as selector from './domain.selector';
import * as constant from './domain.constant';
import initialState from './domain.initialState';
import saga from './domain.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
