import React from 'react';

import { Box } from '@mui/material';

const LoginLayout = ({ children }) => {
  return (
    <Box
      height="100vh"
      bgcolor="primary.main"
      sx={{
        backgroundImage: 'url("/giro_bg_login.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
      }}
    >
      {children}
    </Box>
  );
};

export default LoginLayout;
