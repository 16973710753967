export const NAME = 'debts';

const CONTEXT = 'PDV::DEBTS::DEBTS';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;
const UPDATE_SELECTED = `${CONTEXT}::UPDATE::SELECTED`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_FILTERS = `${CONTEXT}::RESET::FILTERS`;
const RESET_SELECTED = `${CONTEXT}::RESET::SELECTED`;

const SELECT = `${CONTEXT}::SELECT`;
const SELECT_ALL = `${CONTEXT}::SELECT::ALL`;

const UNSELECT = `${CONTEXT}::UNSELECT`;
const UNSELECT_ALL = `${CONTEXT}::UNSELECT::ALL`;

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
  SELECTED: UPDATE_SELECTED,
};

const RESET = {
  STATE: RESET_STATE,
  FILTERS: RESET_FILTERS,
  SELECTED: RESET_SELECTED,
};

const SERVICE = {
  GET: SERVICE_GET,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  SERVICE,
  FETCH,
  UPDATE,
  SELECT,
  SELECT_ALL,
  UNSELECT,
  UNSELECT_ALL,
};
