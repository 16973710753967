import React from 'react';
import { flow } from 'lodash';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { BsPlusSlashMinus } from 'react-icons/bs';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { Paper } from '@mui/material';
import ReactExport from 'react-export-excel';
import { IconContext } from 'react-icons/lib';

import reports from '../store/reports';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function TableReportTransactionsComponent() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const dispatch = useDispatch();

  const selectorRedux = {
    data: useSelector(reports.selector.selectData),
    loading: useSelector(reports.selector.selectLoading),
  };

  const dispatchRedux = {
    updateFilters: flow(reports.action.updateFilters, dispatch),
    resetState: flow(reports.action.resetState, dispatch),
  };

  React.useEffect(() => {
    dispatchRedux.updateFilters({
      type: 'crea',
    });

    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const handleExport = () => {
    const exportButton = document.querySelector(
      '.button-xls-export'
    ) as HTMLElement;

    if (exportButton) {
      exportButton.click();
    }
  };

  const indexOfLast = currentPage * limit;
  const indexOfFirst = indexOfLast - limit;
  const currentData = (selectorRedux.data || []).slice(
    indexOfFirst,
    indexOfLast
  );

  const renderTableCell = (el, idx, row) => {
    const isDate = ['dtTransacao'];
    const isAmount = ['vlTransacao', 'vlLiberado'];

    if (isDate.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {format(new Date(row[el].replace('Z', '')), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </TableCell>
      );
    }

    if (isAmount.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {Number(row[el]).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </TableCell>
      );
    }

    return (
      <TableCell component="th" scope="row" key={`${el}-${idx}`}>
        <Typography variant="trasso_body_extra_small" color="trasso.purple.100">
          {row[el]}
        </Typography>
      </TableCell>
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="300px"
        position="relative"
      >
        {selectorRedux?.data?.length > 0 && !selectorRedux.loading && (
          <>
            <Box
              paddingBottom={3}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Box
                sx={{
                  boxShadow: 1,
                  display: 'inline-flex',
                  justifyContent: 'flex-start',
                  minWidth: '250px',
                }}
                display="grid"
                gridTemplateColumns="1fr 7fr"
              >
                <Box
                  bgcolor="#7460ee"
                  sx={{
                    p: 3,
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <IconContext.Provider
                    value={{ color: 'white', size: '25px' }}
                  >
                    <BsPlusSlashMinus />
                  </IconContext.Provider>
                </Box>
                <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                  <Typography variant="trasso_body_medium" color="trasso.blue">
                    {Number((selectorRedux.data || []).length)}
                  </Typography>
                  <Typography
                    variant="trasso_heading_extra_small"
                    color="trasso.gray.40"
                  >
                    Quantidade
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  boxShadow: 1,
                  ml: 10,
                  display: 'inline-flex',
                  justifyContent: 'flex-start',
                  minWidth: '250px',
                }}
                display="grid"
                gridTemplateColumns="1fr 7fr"
              >
                <Box
                  bgcolor="#ffb22b"
                  sx={{
                    p: 3,
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <IconContext.Provider
                    value={{ color: 'white', size: '25px' }}
                  >
                    <MdOutlineAttachMoney />
                  </IconContext.Provider>
                </Box>
                <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                  <Typography variant="trasso_body_medium" color="trasso.blue">
                    {Number(
                      (selectorRedux.data || []).reduce((a, v) => {
                        return a + v.vlLiberado;
                      }, 0)
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                  <Typography
                    variant="trasso_heading_extra_small"
                    color="trasso.gray.40"
                  >
                    Valor Total
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <Button variant="outlined" onClick={handleExport} size="small">
                Exportar
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ minWidth: { xl: '100%', md: '1300px' } }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {[
                      'Cd. Carrinho',
                      'Nome',
                      'CPF/CNPJ',
                      'Giro Id',
                      'Data',
                      'Valor carrinho',
                      'Tipo',
                      'Parcelas',
                      'Status',
                    ].map((el, idx) => (
                      <TableCell key={`row-${idx}`}>
                        <Typography
                          variant="trasso_heading_extra_small"
                          color="trasso.purple.100"
                        >
                          {el}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentData.map((row) => (
                    <TableRow
                      key={row.dtTransacao}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {[
                        'cdCarrinho',
                        'nomeCliente',
                        'cpfcnpj',
                        'idTransacao',
                        'dtTransacao',
                        'vlLiberado',
                        'tpPagamento',
                        'qtdParcelas',
                        'status',
                      ].map((el, idx) => renderTableCell(el, idx, row))}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      count={selectorRedux.data?.length || 0}
                      rowsPerPage={limit}
                      page={currentPage - 1}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={(ev, nextPage) =>
                        setCurrentPage(nextPage + 1)
                      }
                      onRowsPerPageChange={(ev: any) =>
                        setLimit(ev.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}

        {selectorRedux?.data?.length === 0 && !selectorRedux.loading && (
          <Typography>Nenhum dado encontrado para essa pesquisa.</Typography>
        )}

        {selectorRedux.loading && (
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            left={0}
            bgcolor="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      <ExcelFile
        filename={'Relatório'}
        element={
          <button
            className="button-xls-export"
            style={{ display: 'none' }}
          ></button>
        }
      >
        <ExcelSheet data={selectorRedux.data} name="CREA">
          <ExcelColumn label="Cd. Carrinho" value="cdCarrinho" />
          <ExcelColumn label="Nome" value="nomeCliente" />
          <ExcelColumn label="CPF/CNPJ" value="cpfcnpj" />
          <ExcelColumn label="Giro Id" value="idTransacao" />
          <ExcelColumn
            label="Data"
            value={({ dtTransacao }) =>
              format(new Date(dtTransacao), 'dd/MM/yyyy HH:mm')
            }
          />
          <ExcelColumn label="Valor carrinho" value="vlLiberado" />
          <ExcelColumn label="Tipo" value="tpPagamento" />
          <ExcelColumn label="Parcelas" value="qtdParcelas" />
          <ExcelColumn label="Status" value="status" />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
}
