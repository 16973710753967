import React from 'react';
import { GlobalStyles, Box, Typography, Divider, Button } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import LogoComponent from '@giro/shared-components/Logo.component';
import TablePaymentComponent from '@giro-pdv/modules/pdv/components/TablePayment.component';

import tablePayment from '@giro-pdv/modules/pdv/store/tablePayment';

const SimulationScreen = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!query.get('amount')) {
      history.goBack();
    }

    dispatch(tablePayment.action.updateAmount(Number(query.get('amount'))));

    setTimeout(() => {
      window.print();

      window.addEventListener('afterprint', () => {
        window.close();
      });
    }, 200);
  }, []);

  return (
    <Box>
      <GlobalStyles
        styles={{
          '#header-app, #footer-app, .MuiAlert-root': {
            display: 'none !important',
          },
        }}
      />

      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={1}
          position="relative"
        >
          <Box
            position="absolute"
            left={0}
            top={0}
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
          >
            <Button
              startIcon={<ChevronLeftIcon />}
              onClick={() => {
                history.goBack();
              }}
            >
              Voltar
            </Button>
          </Box>

          <LogoComponent variant="new" width="250px" />
        </Box>

        <Box
          sx={{
            '& > div > div': {
              pr: '0 !important',
            },
          }}
        >
          <TablePaymentComponent />
        </Box>

        <Divider
          flexItem
          sx={{
            mt: 3,
            mb: 3,
            '@media print': {
              display: 'none',
            },
          }}
        />

        <Box
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={'column'}
        >
          <Button
            startIcon={<FileDownloadOutlinedIcon />}
            variant="outlined"
            onClick={() => {
              window.print();
            }}
          >
            Fazer download
          </Button>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          justifyContent="center"
        >
          <Typography variant="caption">Simulação feita no dia: </Typography>
          <Typography variant="caption" fontWeight={800}>
            {moment().format('DD/MM/YYYY [às] HH:mm')}
          </Typography>
        </Box>

        <Divider
          flexItem
          sx={{
            mt: 3,
            mb: 3,
            '@media print': {
              display: 'none',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SimulationScreen;
