import { combineReducers } from 'redux';

import domain from './domain';
import debts from './debts';

const reducer = combineReducers({
  [domain.name]: domain.reducer,
  [debts.name]: debts.reducer,
});

export default reducer;
