export const NAME = 'pinpad';

const CONTEXT = 'PDV::PINPAD';

const SERVICE_POST_START = `${CONTEXT}::SERVICE::POST::START`;
const SERVICE_POST_CONTINUE = `${CONTEXT}::SERVICE::POST::CONTINUE`;
const SERVICE_POST_FINISH = `${CONTEXT}::SERVICE::POST::FINISH`;
const SERVICE_POST_IS_PRESENT = `${CONTEXT}::SERVICE::POST::IS_PRESENT`;
const SERVICE_POST_NEW_SESSION = `${CONTEXT}::SERVICE::POST::NEW_SESSION`;
const SERVICE_POST_DESTROY_SESSION = `${CONTEXT}::SERVICE::POST::DESTROY_SESSION`;
const SERVICE_POST_MESSAGE = `${CONTEXT}::SERVICE::POST::MESSAGE`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const UPDATE_SESSION = `${CONTEXT}::UPDATE::SESSION`;
const UPDATE_MESSAGE = `${CONTEXT}::UPDATE::MESSAGE`;
const UPDATE_TEF = `${CONTEXT}::UPDATE::TEF`;
const UPDATE_STATUS = `${CONTEXT}::UPDATE::STATUS`;
const UPDATE_PRESENT = `${CONTEXT}::UPDATE::PRESENT`;
const UPDATE_TRANSACTION_STATUS = `${CONTEXT}::UPDATE::TRANSACTION_STATUS`;

const UPDATE = {
  SESSION: UPDATE_SESSION,
  MESSAGE: UPDATE_MESSAGE,
  PRESENT: UPDATE_PRESENT,
  STATUS: UPDATE_STATUS,
  TEF: UPDATE_TEF,
  TRANSACTION_STATUS: UPDATE_TRANSACTION_STATUS,
};

const SERVICE = {
  POST_START: SERVICE_POST_START,
  POST_CONTINUE: SERVICE_POST_CONTINUE,
  POST_FINISH: SERVICE_POST_FINISH,
  POST_IS_PRESENT: SERVICE_POST_IS_PRESENT,
  POST_NEW_SESSION: SERVICE_POST_NEW_SESSION,
  POST_DESTROY_SESSION: SERVICE_POST_DESTROY_SESSION,
  POST_MESSAGE: SERVICE_POST_MESSAGE,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

const RESET = {
  STATE: RESET_STATE,
};

export const ACTION_TYPES = {
  SERVICE,
  FETCH,
  RESET,
  UPDATE,
};
