import { Switch, Route, Redirect } from 'react-router-dom';

import useIsCreaHook from '@giro-pdv/hooks/useIsCrea.hook';

import useProtectedByAuth from '@giro-pdv/hooks/useProtectedByAuth.hook';

import AppLayout from '@giro-pdv/layouts/App.layout';

import ROUTES from '@giro-pdv/constants/routes.constant';

import TransactionsScreen from './screens/ReportsEC.screen';
import ReportsCreaScreen from './screens/ReportsCrea.screen';
import ReportsFinancingScreen from './screens/ReportsFinancing.screen';

const ReportsModule = () => {
  useProtectedByAuth();

  const isCrea = useIsCreaHook();

  const ROOTROUTER = isCrea ? ROUTES.REPORTS.CREA : ROUTES.REPORTS.EC;

  return (
    <AppLayout>
      <Switch>
        {!isCrea && (
          <Route
            exact
            path={ROUTES.REPORTS.EC}
            component={TransactionsScreen}
          />
        )}

        {!isCrea && (
          <Route
            exact
            path={ROUTES.REPORTS.FINANCING}
            component={ReportsFinancingScreen}
          />
        )}

        {isCrea && (
          <Route
            exact
            path={ROUTES.REPORTS.CREA}
            component={ReportsCreaScreen}
          />
        )}

        <Redirect to={ROOTROUTER} />
      </Switch>
    </AppLayout>
  );
};

export default ReportsModule;
