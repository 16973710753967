import reducer from './login.reducer';
import * as selector from './login.selector';
import * as constant from './login.constant';
import subscriber from './login.subscriber';
import saga from './login.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
