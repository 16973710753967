import reducer from './amount.reducer';
import * as action from './amount.action';
import * as selector from './amount.selector';
import * as constant from './amount.constant';
import initialState from './amount.initialState';
import saga from './amount.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
