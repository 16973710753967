import { all } from 'redux-saga/effects';

import monitorings from './monitorings';

function* watch() {
  yield all([monitorings.saga.watch()]);
}

export default {
  watch,
};
