import reducer from './operators.reducer';
import * as action from './operators.action';
import * as selector from './operators.selector';
import * as constant from './operators.constant';
import initialState from './operators.initialState';
import saga from './operators.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
