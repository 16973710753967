export const NAME = 'tablePayment';

const CONTEXT = 'PDV::TABLE_PAYMENT';

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const UPDATE_AMOUNT = `${CONTEXT}::UPDATE::AMOUNT`;
const UPDATE_INSTALLMENT_SELECTED = `${CONTEXT}::UPDATE::INSTALLMENT_SELECTED`;
const UPDATE_TYPE_TAX = `${CONTEXT}::UPDATE::TYPE_TAX`;
const UPDATE_RETURN = `${CONTEXT}::UPDATE::RETURN`;

const UPDATE = {
  AMOUNT: UPDATE_AMOUNT,
  INSTALLMENT_SELECTED: UPDATE_INSTALLMENT_SELECTED,
  RETURN: UPDATE_RETURN,
  TYPE_TAX: UPDATE_TYPE_TAX,
};

const RESET = {
  STATE: RESET_STATE,
};

export const ACTION_TYPES = { UPDATE, RESET };
