import reducer from './dialogPayTerminal.reducer';
import * as action from './dialogPayTerminal.action';
import * as selector from './dialogPayTerminal.selector';
import * as constant from './dialogPayTerminal.constant';
import initialState from './dialogPayTerminal.initialState';
import subscriber from './dialogPayTerminal.subscriber';
import saga from './dialogPayTerminal.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
