import reducer from './dialogs.reducer';
import * as selector from './dialogs.selector';
import * as constant from './dialogs.constant';
import subscriber from './dialogs.subscriber';
import saga from './dialogs.saga';

export default {
  reducer,
  constant,
  selector,
  subscriber,
  saga,
  name: constant.NAME,
};
