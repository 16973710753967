import reducer from './recharge.reducer';
import * as selector from './recharge.selector';
import * as constant from './recharge.constant';
import subscriber from './recharge.subscriber';
import saga from './recharge.saga';

export default {
  reducer,
  constant,
  selector,
  subscriber,
  saga,
  name: constant.NAME,
};
