import fetch from '@giro/shared-utils/fetch.util';

type Filters = {
  phoneNumber: string;
};

export default async function getApiOperatorsPhonenumberService(
  filters: Filters
) {
  const { phoneNumber } = filters;

  return fetch(`operators/${phoneNumber}`, {
    method: 'GET',
    ms: 'PDV',
    auth: true,
  });
}
