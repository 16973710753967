import { combineReducers } from 'redux';

import dialogs from './dialogs';
import recharge from './recharge';
import tablePayment from './tablePayment';
import pinpad from './pinpad';
import fees from './fees';
import clients from './clients';
import transactions from './transactions';
import debts from './debts';
import sync from './sync';

const reducer = combineReducers({
  [sync.name]: sync.reducer,
  [debts.name]: debts.reducer,
  [transactions.name]: transactions.reducer,
  [clients.name]: clients.reducer,
  [fees.name]: fees.reducer,
  [pinpad.name]: pinpad.reducer,
  [tablePayment.name]: tablePayment.reducer,
  [recharge.name]: recharge.reducer,
  [dialogs.name]: dialogs.reducer,
});

export default reducer;
