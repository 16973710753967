import reducer from './dialogChangeEmail.reducer';
import * as action from './dialogChangeEmail.action';
import * as selector from './dialogChangeEmail.selector';
import * as constant from './dialogChangeEmail.constant';
import initialState from './dialogChangeEmail.initialState';
import subscriber from './dialogChangeEmail.subscriber';
import saga from './dialogChangeEmail.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
