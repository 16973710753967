import { createReducer } from '@reduxjs/toolkit';

import initialState from './router.initialState';
import { ACTION_TYPES } from './router.constant';

const handleRouteChange = (state, action) => {
  state.pathname = action.payload.pathname;
  state.search = action.payload.search;
  state.hash = action.payload.hash;
};

export default createReducer(initialState, {
  [ACTION_TYPES.ROUTE_CHANGED]: handleRouteChange,
});
