import * as React from 'react';
import { flow } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';

import messagesSchema from '@giro/shared-constants/messagesSchema.constant';

import FieldPhoneComponent from '@giro/shared-components/Fields/FieldPhone.component';

import useDialogHook from '@giro-pdv/hooks/useDialogRedux.hook';

import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';
import TextFieldLabelComponent from '@giro-pdv/components/TextFieldLabel.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';

export default function DialogSendPaymentComponent() {
  const dispatch = useDispatch();

  const dispatchRedux = {
    servicePost: flow(dialogSendPayment.action.servicePost, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(dialogSendPayment.selector.selectLoading),
  };

  const { handleClose, visible } = useDialogHook(dialogSendPayment);

  const schema = Yup.object({
    type: Yup.string().required(messagesSchema.REQUIRED).default('email'),
    destination: Yup.string()
      .when('type', {
        is: 'email',
        then: Yup.string()
          .email(messagesSchema.EMAIL)
          .required(messagesSchema.REQUIRED),
      })
      .when('type', {
        is: 'whatsapp',
        then: Yup.string()
          .required(messagesSchema.REQUIRED)
          .min(11, messagesSchema.MIN),
      }),
  });

  const initialValues = {
    type: 'Email',
    destination: '',
  };

  const onSubmit = async (values) => {
    dispatchRedux.servicePost(values);

    return true;
  };

  return (
    <Dialog open={visible} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={schema}
      >
        {({ errors, values, setFieldValue, submitForm }) => (
          <Form>
            <DialogTitle>
              <Typography variant="trasso_body_medium" color="trasso.purple.80">
                Link de pagamento
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 500 }}>
              <DialogContentText>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={values.type}
                  onChange={(ev, value) => {
                    setFieldValue('type', value);
                    setFieldValue('destination', '');
                  }}
                >
                  <FormControlLabel
                    value="Email"
                    control={<Radio />}
                    label={
                      <Typography variant="trasso_body_medium">
                        E-mail
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="Whatsapp"
                    control={<Radio />}
                    label={
                      <Typography variant="trasso_body_medium">
                        Whatsapp
                      </Typography>
                    }
                  />
                </RadioGroup>
              </DialogContentText>
              <Box mt={2}>
                {values.type === 'Email' && (
                  <FieldLabelFormikComponent
                    name="destination"
                    autoFocus
                    type="email"
                    size="small"
                  />
                )}

                {values.type === 'Whatsapp' && (
                  <FieldPhoneComponent
                    customInput={TextFieldLabelComponent}
                    name="destination"
                    autoFocus
                    label=""
                    size="small"
                  />
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fechar</Button>
              <LoadingButton
                loading={selectorRedux.loading}
                onClick={submitForm}
                disabled={
                  selectorRedux.loading || Object.keys(errors).length > 0
                }
              >
                Enviar
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
