import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const FieldPasswordComponent = ({ name, ...props }) => {
  const [show, setShow] = React.useState(false);

  const { component } = props;

  return (
    <Field
      name={name}
      component={component || TextField}
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default FieldPasswordComponent;
