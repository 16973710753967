import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ROUTES from '@giro-pdv/constants/routes.constant';

import useProtectedByAuth from '@giro-pdv/hooks/useProtectedByAuth.hook';

import AppLayout from '@giro-pdv/layouts/App.layout';

import MonitoringScreen from './screens/Monitoring.screen';

const MonitoringModule = () => {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route
          path={ROUTES.MONITORING.ROOT}
          exact
          component={MonitoringScreen}
        />
      </Switch>
    </AppLayout>
  );
};

export default MonitoringModule;
