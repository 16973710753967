import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

import TablePaymentComponent from '@giro-pdv/modules/pdv/components/TablePayment.component';

const PDVLayout = ({ children }) => {
  const { pathname } = useLocation();
  const formContainerRef: any = React.useRef(null);

  const [height, setHeight] = React.useState<any>(null);

  React.useEffect(() => {
    setHeight(formContainerRef.current.offsetHeight);
  }, [pathname]);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ lg: '1fr 500px', xs: '1fr' }}
      alignItems="start"
      gap={3}
    >
      <Box display="flex" flexDirection="column" gap={4} ref={formContainerRef}>
        {children}
      </Box>

      <Box overflow="scroll" height={height} minHeight="calc(100vh - 372px)">
        <TablePaymentComponent />
      </Box>
    </Box>
  );
};

export default PDVLayout;
