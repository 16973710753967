import { flow } from 'lodash';

import { NAME } from './toaster.constant';

const selectStateKey = (state) => state[NAME];
const selectMessageKey = (state) => state.message;
const selectVariantKey = (state) => state.variant;
const selectConfigsKey = (state) => state.configs;

export const selectState = flow(selectStateKey);
export const selectMessage = flow(selectState, selectMessageKey);
export const selectVariant = flow(selectState, selectVariantKey);
export const selectConfigs = flow(selectState, selectConfigsKey);
