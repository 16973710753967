const initialState = {
  loading: null,
  data: null,
  error: null,
  selected: [],
  filters: {
    offset: 0,
    limit: 999999999,
    integration_id: '',
  },
};

export default initialState;
