const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  filters: {
    dateStart: new Date(),
    dateEnd: new Date(),
    type: null,
  },
};

export default initialState;
