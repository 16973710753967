import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';

type FiltersType = {
  dateStart: string;
  dateEnd: string;
};

export default async function getApiPdvTransactionsService(
  filters: FiltersType
) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['pdv/transactions', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'REPORTS',
    auth: true,
  });
}
