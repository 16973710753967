import { takeLatest, call, put } from 'redux-saga/effects';

import {
  deleteAuthCookie,
  setAuthCookie,
} from '@giro/shared-utils/authCookie.util';

import * as actions from './auth.action';
import { ACTION_TYPES } from './auth.constant';

function* handleUnsetToken() {
  yield call(deleteAuthCookie);
}

function* handleSetToken(action) {
  yield call(setAuthCookie, action.token);
}

export function* handleRefreshToken(service) {
  const [success, result] = yield call(service);

  if (success) {
    yield put(actions.setToken(result.token));
  }

  return [success, result];
}

function* watch() {
  yield takeLatest(ACTION_TYPES.TOKEN.UNSET, handleUnsetToken);
  yield takeLatest(ACTION_TYPES.TOKEN.SET, handleSetToken);
}

export default {
  watch,
};
