import React from 'react';

import logoFull from '@giro/shared-assets/logo/logo_full.png';
import logoSmall from '@giro/shared-assets/logo/logo_small.png';
import logoLarge from '@giro/shared-assets/logo/logo_large.png';
import logoWhite from '@giro/shared-assets/logo/logo_white.png';
import logoNew from '@giro/shared-assets/newLogo/Giro_Pagamentos_2linhas_Principal_FundoClaro.png';
import logoNewWhite from '@giro/shared-assets/newLogo/Giro_Pagamentos_2linhas_Monocromatico_Branco.png';
import logoNewSym from '@giro/shared-assets/newLogo/Giro_Simbolo_Principal_FundoClaro.png';
import logoNewPdv from '@giro/shared-assets/newLogo/Logo_Giro_Branco_PDV.png';
import logoEvora from '@giro/shared-assets/evoraLogo/logo_evora.png';

export type LogoProps = {
  size?: 'full' | 'small' | 'large' | any;
  variant?: 'default' | 'white' | 'new' | 'pdv' | any;
  [key: string]: any;
};

const LogoComponent: React.FC<LogoProps> = ({ size, variant, ...props }) => {
  let images = {
    full: logoFull,
    small: logoSmall,
    large: logoLarge,
  };

  if (variant === 'white') {
    images = {
      full: logoWhite,
      small: logoWhite,
      large: logoWhite,
    };
  }

  if (variant === 'new') {
    images = {
      full: logoNew,
      small: logoNew,
      large: logoNew,
    };
  }

  if (variant === 'new-white') {
    images = {
      full: logoNewWhite,
      small: logoNewWhite,
      large: logoNewWhite,
    };
  }

  if (variant === 'new-sym') {
    images = {
      full: logoNewSym,
      small: logoNewSym,
      large: logoNewSym,
    };
  }

  if (variant === 'pdv') {
    images = {
      full: logoNewPdv,
      small: logoNewPdv,
      large: logoNewPdv,
    };
  }

  if (variant === 'evora') {
    images = {
      full: logoEvora,
      small: logoEvora,
      large: logoEvora,
    };
  }

  return <img src={images[size || 'full']} {...props} />;
};

export default LogoComponent;
