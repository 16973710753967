import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

const AboutText = () => {
  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Typography align="justify" variant="h2">
        Esta plataforma destina a operação do Sistema Integrado de Pagamentos
        Eletrônicos – SIPE, do Operador Nacional do Registro Civil do Brasil
      </Typography>
      <Typography align="center" variant="h3">
        Base legal: Provimento 127/2022 - Corregedoria Nacional de Justiça.
      </Typography>
      <Typography align="justify" variant="h5">
        I – O Pix, quando cobrado ao destinatário da transferência, terá o seu
        custo suportado pelo gestor da plataforma do Sistema Integrado de
        Pagamentos Eletrônicos – SIPE, sem nenhum repasse correspondente aos
        usuários;
      </Typography>
      <Typography align="justify" variant="h5">
        II – Os custos da intermediação financeira e/ou de eventual parcelamento
        por cartão de crédito cobrados pela operadora ou administradora
        autorizada a funcionar pelo Banco Central do Brasil serão repassados ao
        usuário e por ele suportados, mediante a inclusão dos valores
        respectivos no pagamento devido;
      </Typography>
      <Typography align="justify" variant="h5">
        III – O custo do boleto, quando esta for a opção do usuário, pessoa
        jurídica ou física, será incluído no valor devido pela prática do ato,
        devendo essa tarifa ser especificadamente demonstrada de modo claro e
        transparente pelo gestor, na plataforma e no corpo do respectivo boleto.
      </Typography>
    </Box>
  );
};

const AboutScreen = () => {
  return (
    <Box>
      <AboutText />
    </Box>
  );
};

export default AboutScreen;
