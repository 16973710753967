import { createReducer } from '@reduxjs/toolkit';

import initialState from './changePassword.initialState';
import { ACTION_TYPES } from './changePassword.constant';

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.error = [false, null];
  state.data = action.payload;
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
};

const handleResetState = () => initialState;

const handleResetError = (state) => {
  state.error = [initialState.error[0], state.error[1]];
};

export default createReducer(initialState, {
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.RESET.ERROR]: handleResetError,
});
