import { useMemo } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import rootReducer from './root.reducer';
import rootSubscribe from './root.subscriber';
import rootSaga from './root.saga';

const initSaga = () => {
  const composeWithReduxDevTools =
    (window as any).window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, LogRocket.reduxMiddleware()];

  const composedEnhancers = composeWithReduxDevTools(
    applyMiddleware(...middlewares)
  );

  const store = createStore(rootReducer, composedEnhancers);

  sagaMiddleware.run(rootSaga);

  return store;
};

export function useStore() {
  const currentStore = useMemo(() => initSaga(), []);

  currentStore.subscribe(rootSubscribe(currentStore));

  return currentStore;
}
