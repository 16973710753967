import React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';
import { TextField } from '@mui/material';

const FieldPhoneComponent = ({ name, ...props }) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched, isSubmitting },
    meta: { error, touched },
  }: any) => {
    const lenghtValue = String(field.value)?.length;

    return (
      <>
        <NumberFormat
          customInput={TextField}
          sx={{
            '& .MuiOutlinedInput-root': {
              color: 'trasso.purple.100',
              '& fieldset': {
                borderColor: 'trasso.gray.40',
              },
            },
          }}
          format={lenghtValue < 11 ? '(##) ####-#####' : '(##) #####-####'}
          fullWidth
          disabled={isSubmitting}
          label="DDD + Celular"
          variant="outlined"
          type="tel"
          value={field.value}
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);
          }}
          onValueChange={(values: any) =>
            setFieldValue(field.name, values.value)
          }
          {...props}
        />
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldPhoneComponent;
