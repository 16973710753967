import reducer from './reportsFinancing.reducer';
import * as constant from './reportsFinancing.constant';
import * as action from './reportsFinancing.action';
import * as selector from './reportsFinancing.selector';
import saga from './reportsFinancing.saga';
import initialState from './reportsFinancing.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
