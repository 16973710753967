import { ACTION_TYPES } from './domain.constant';

export const serviceGet = () => ({
  type: ACTION_TYPES.SERVICE.GET,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchSuccess = (payload) => ({
  type: ACTION_TYPES.FETCH.SUCCESS,
  payload,
});

export const fetchError = () => ({
  type: ACTION_TYPES.FETCH.ERROR,
});

export const updateFilters = (filters) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  filters,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
