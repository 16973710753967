import { all } from 'redux-saga/effects';

import dialogs from './dialogs';
import recharge from './recharge';
import tablePayment from './tablePayment';
import pinpad from './pinpad';
import fees from './fees';
import clients from './clients';
import transactions from './transactions';
import debts from './debts';
import sync from './sync';

function* watch() {
  yield all([
    sync.saga.watch(),
    debts.saga.watch(),
    transactions.saga.watch(),
    clients.saga.watch(),
    fees.saga.watch(),
    dialogs.saga.watch(),
    tablePayment.saga.watch(),
    pinpad.saga.watch(),
    recharge.saga.watch(),
  ]);
}

export default {
  watch,
};
