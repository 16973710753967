import * as authCookie from './authCookie.util';

import { API_URL_WITH_MS } from '@giro/shared-configs/api.config';

const normalizeAPIResponse = async (res: any) => {
  const body = await res.json();

  return [res.ok, body];
};

const fetch = async (url: string, config: any = {}) => {
  const { ms, auth, token, defaultHeaders = true, ...restConfig } = config;

  try {
    const response = await window.fetch(
      `${API_URL_WITH_MS(ms)}/${url}`,
      Object.assign({}, restConfig, {
        headers: new Headers(
          Object.assign(
            defaultHeaders && {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            auth &&
              (token
                ? {
                    Authorization: `${token}`,
                  }
                : authCookie.hasAuthCookie() && {
                    Authorization: authCookie.getAuthCookie(),
                  }),
            restConfig.headers
          )
        ),
      })
    );

    if (response.status === 401) {
      authCookie.deleteAuthCookie();

      window.location.reload();
    }

    const [success, data] = await normalizeAPIResponse(response);

    return [success, data];
  } catch (e) {
    return [false, null];
  }
};

export default fetch;
