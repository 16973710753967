import React from 'react';
import { Box } from '@mui/system';
import { Divider, Typography, Checkbox } from '@mui/material';

const normalizeType = {
  LIC: 'Licenciamento',
};

const BoxDebitComponent = ({ name, amount, onChangeCheck, checked, type }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={'20px'}
      width="100%"
      bgcolor={checked ? 'white' : 'transparent'}
      border={'1px solid'}
      borderColor={checked ? 'secondary.main' : 'primary.main'}
      borderRadius={'6px'}
      py={0.5}
      px={0.5}
      alignItems="start"
    >
      <Box display="flex" position="relative" alignItems="start" gap={1}>
        <Checkbox
          color="secondary"
          onChange={onChangeCheck}
          checked={checked}
        />

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography
            variant="subtitle1"
            color="trasso.purple.100"
            fontWeight={700}
          >
            {normalizeType[type] || type}
          </Typography>
          <Typography variant="caption" color="trasso.purple.100">
            {name}
          </Typography>

          <Typography color="secondary.main">
            {Number(amount).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BoxDebitComponent;
