import { flow } from 'lodash';

import { NAME } from './transactions.constant';

import { selectState as selectStatePdv } from '../pdv.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectDataKey = (state) => state.data;
const selectCurrentKey = (state) => state.current;

export const selectState = flow(selectStatePdv, selectStateKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectCurrent = flow(selectState, selectCurrentKey);
export const selectData = flow(selectState, selectDataKey);
