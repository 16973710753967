import * as React from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import format from 'date-fns/format';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
import { jsPDF } from 'jspdf';

import ROUTES from '@giro-pdv/constants/routes.constant';

import useDialogHook from '@giro-pdv/hooks/useDialogRedux.hook';

import dialogReceipt from '../store/dialogReceipt';
import receipt from '@giro-pdv/modules/receipt/store/receipt';

export default function DialogReceiptComponent() {
  const history = useHistory();
  const dispatch = useDispatch();

  const dispatchRedux = {
    serviceGet: flow(dialogReceipt.action.serviceGet, dispatch),
    reset: flow(dialogReceipt.action.reset, dispatch),
    fetchEnd: flow(receipt.action.fetchEnd, dispatch),
  };

  const selectorRedux = {
    data: useSelector(dialogReceipt.selector.selectData),
    loading: useSelector(dialogReceipt.selector.selectLoading),
  };

  const { handleClose, visible } = useDialogHook(dialogReceipt);

  React.useEffect(() => {
    if (visible) {
      dispatchRedux.serviceGet();
    } else {
      dispatchRedux.reset();
    }
  }, [visible]);

  const {
    bandeira,
    binCartao,
    softDescriptor,
    nuCPFCNPJ,
    cidade,
    dtTransacao = Date.now(),
    vlTransacao,
    idTransacao,
    tipoPagamento,
    parcelas,
  } = selectorRedux?.data || {};

  const downloadDocument = async () => {
    const input: any = document.getElementById('divToPrint');

    const imgData = await html2canvas(input);

    const pdf = new jsPDF('p', 'mm', [80, 297]);
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, 'PNG', 10, 10, width, height - 120);

    const blob = pdf.output('arraybuffer');

    const pdfNew = await PDFDocument.load(blob);

    const linkNewPdf = await pdfNew.saveAsBase64({ dataUri: true });
    const nameSource = `comprovante_${idTransacao}_${+Date.now()}.pdf`;

    const downloadLink = document.createElement('a');

    downloadLink.href = linkNewPdf;
    downloadLink.download = nameSource;
    downloadLink.click();
  };

  const printDocument = async () => {
    const input: any = document.getElementById('divToPrint');

    const imgData = await html2canvas(input);

    const pdf = new jsPDF('p', 'mm', [80, 297]);
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, 'PNG', 10, 10, width, height - 120);

    pdf.autoPrint();

    pdf.output('dataurlnewwindow');
  };

  const handleRedirectToReceipt = () => {
    dispatchRedux.fetchEnd(selectorRedux.data);

    dispatchRedux.reset();

    history.push(ROUTES.RECEIPT.ROOT);
  };

  return (
    <Dialog open={visible} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="trasso_body_medium" color="trasso.purple.80">
            Recibo da transação
          </Typography>
        </Box>
      </DialogTitle>
      <Box
        minWidth={400}
        display="flex"
        flexDirection="column"
        gap={2}
        px={3}
        pb={3}
        position="relative"
      >
        <Box display="flex" flexDirection="column" gap={2} id="divToPrint">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="trasso_body_medium">
              GIRO PAGAMENTOS
            </Typography>
            <Typography variant="trasso_body_small">COMPROVANTE</Typography>
            <Box display="flex" flexDirection="column">
              <Typography variant="trasso_body_small">{bandeira}</Typography>
              <Typography variant="trasso_body_small">{binCartao}</Typography>
            </Box>
            <Typography variant="trasso_body_small">
              VIA ESTABELECIMENTO
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography variant="trasso_body_small">
                {softDescriptor}
              </Typography>
              <Typography variant="trasso_body_small">{nuCPFCNPJ}</Typography>
            </Box>
            <Typography variant="trasso_body_small">{cidade}</Typography>
            <Box display="flex" flexDirection="row" gap={0.5}>
              <Typography variant="trasso_body_small">
                {format(new Date(dtTransacao), 'dd/MM/yyyy')}
              </Typography>
              <Typography variant="trasso_body_small">
                {format(new Date(dtTransacao), 'HH:mm')}
              </Typography>
            </Box>
            <Typography variant="trasso_body_small">
              {tipoPagamento} {parcelas}x
            </Typography>
            <Typography variant="trasso_body_small">
              VALOR:{' '}
              {Number(vlTransacao).toLocaleString('pt-br', {
                currency: 'BRL',
                style: 'currency',
              })}
            </Typography>
            <Typography variant="trasso_body_small">
              AUTORIZAÇÃO: {idTransacao}
            </Typography>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="trasso_body_medium">
              GIRO PAGAMENTOS
            </Typography>
            <Typography variant="trasso_body_small">COMPROVANTE</Typography>
            <Box display="flex" flexDirection="column">
              <Typography variant="trasso_body_small">{bandeira}</Typography>
              <Typography variant="trasso_body_small">{binCartao}</Typography>
            </Box>
            <Typography variant="trasso_body_small">VIA CLIENTE</Typography>
            <Box display="flex" flexDirection="column">
              <Typography variant="trasso_body_small">
                {softDescriptor}
              </Typography>
              <Typography variant="trasso_body_small">{nuCPFCNPJ}</Typography>
            </Box>
            <Typography variant="trasso_body_small">{cidade}</Typography>
            <Box display="flex" flexDirection="row" gap={0.5}>
              <Typography variant="trasso_body_small">
                {format(new Date(dtTransacao), 'dd/MM/yyyy')}
              </Typography>
              <Typography variant="trasso_body_small">
                {format(new Date(dtTransacao), 'HH:mm')}
              </Typography>
            </Box>
            <Typography variant="trasso_body_small">
              {tipoPagamento} {parcelas}x
            </Typography>
            <Typography variant="trasso_body_small">
              VALOR:{' '}
              {Number(vlTransacao).toLocaleString('pt-br', {
                currency: 'BRL',
                style: 'currency',
              })}
            </Typography>
            <Typography variant="trasso_body_small">
              AUTORIZAÇÃO: {idTransacao}
            </Typography>
          </Box>
        </Box>

        <Button variant="contained" onClick={printDocument}>
          Imprimir comprovante
        </Button>
        <Button variant="contained" onClick={downloadDocument}>
          Baixar comprovante
        </Button>
        <Button variant="outlined" onClick={handleRedirectToReceipt}>
          Baixar recibo
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Fechar
        </Button>

        {selectorRedux.loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bgcolor="white"
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
