import React from 'react';
import NumberFormat from 'react-number-format';
import { Field, useFormikContext, getIn } from 'formik';
import { TextField } from '@mui/material';

const FieldCurrencyComponent = ({
  name,
  onBlur = (val) => {},
  onChange = (val) => {},
  ...restProps
}) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
    meta: { error, touched },
  }: any) => {
    return (
      <>
        <NumberFormat
          customInput={TextField}
          prefix={'R$ '}
          isNumericString
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          fixedDecimalScale
          fullWidth
          type="tel"
          label="Valor"
          value={field.value}
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);

            if (onBlur) {
              onBlur(field.value);
            }
          }}
          onValueChange={(values: any) => {
            setFieldValue(field.name, values.value);

            if (onChange) {
              onChange(values.value);
            }
          }}
          {...restProps}
        />
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export const FieldCurrencyComponentDigits = ({
  name,
  onBlur = (val) => {},
  onChange = (val) => {},
  ...restProps
}) => {
  const formik = useFormikContext();

  const [value, setValue] = React.useState<string | number>('');

  React.useEffect(() => {
    const value = getIn(formik.values, name);

    setValue(value * 10000);
  }, [formik.values]);
  const handleChange = (v: any, setFieldValue, field) => {
    setValue(parseFloat(v.value) * 100);

    setFieldValue(field.name, v.floatValue / 100);

    if (onChange) {
      onChange(v.floatValue / 100);
    }
  };

  const currencyFormatter = (formatted_value: any) => {
    if (!Number(formatted_value)) return 'R$ 0,00';
    const br = { style: 'currency', currency: 'BRL' };
    return new Intl.NumberFormat('pt-BR', br).format(formatted_value / 100);
  };

  const keyDown = (e: any) => {
    if (e.code === 'Backspace' && !value) {
      e.preventDefault();
    }

    if (e.code === 'Backspace' && Number(value) < 1000) {
      e.preventDefault();
      setValue(0);
    }
  };

  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
    meta: { error, touched },
  }: any) => {
    return (
      <>
        <NumberFormat
          sx={{
            '& .MuiOutlinedInput-root': {
              color: 'trasso.purple.100',
              '& fieldset': {
                borderColor: 'trasso.gray.40',
              },
            },
          }}
          value={Number(value) / 100}
          format={currencyFormatter}
          onValueChange={(v) => handleChange(v, setFieldValue, field)}
          prefix={'R$ '}
          allowEmptyFormatting
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          customInput={TextField}
          onKeyDown={keyDown}
          fullWidth
          type="tel"
          label="Valor"
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);

            if (onBlur) {
              onBlur(field.value);
            }
          }}
          {...restProps}
        />
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldCurrencyComponent;
