import { flow } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import getApiAppsService from '@giro/shared-services/debts/getApiApps.service';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

function* handleServiceGet() {
  const showToaster = flow(toaster.action.show, put);

  const filters = yield select(currentStore.selector.selectFilters);

  yield put(currentStore.action.fetchStart());

  try {
    const [success, result] = yield call(getApiAppsService, filters);

    if (!success) {
      throw result;
    }

    const newResult = [...result];

    const indexPR = newResult.findIndex(
      (item) => item.description === 'Paraná'
    );

    newResult[indexPR] = {
      ...newResult[indexPR],
      description: 'Paraná (Em breve)',
    };

    yield put(currentStore.action.fetchSuccess(newResult));
  } catch (e) {
    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
