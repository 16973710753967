import { takeLatest, select, call, put } from 'redux-saga/effects';
import format from 'date-fns/format';

import fetch from '@giro/shared-utils/fetch.util';

import currentStore from '.';

import getApiReportsService from '@giro/shared-services/pdv/getApiReports.service';

function* handleFetchAPI(action) {
  const { payload } = action;

  yield put(currentStore.action.fetchStart());

  const [success, body] = yield call(async () =>
    fetch('reports-financing', {
      method: 'GET',
      ms: 'PDV',
      auth: true,
    })
  );

  if (!success) {
    return yield put(currentStore.action.fetchError(body));
  }

  return yield put(
    currentStore.action.fetchEnd(
      body?.map((elm) => ({
        ...elm,
        metadata: JSON.parse(elm?.metadata || '{}'),
      }))
    )
  );
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleFetchAPI
  );
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleFetchAPI
  );
}

export default {
  watch,
};
