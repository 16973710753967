import reducer from './tablePayment.reducer';
import * as action from './tablePayment.action';
import * as selector from './tablePayment.selector';
import * as constant from './tablePayment.constant';
import initialState from './tablePayment.initialState';
import saga from './tablePayment.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
