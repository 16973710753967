import { all } from 'redux-saga/effects';

import transactions from './reports';
import reportsFinancing from './reportsFinancing';

function* watch() {
  yield all([transactions.saga.watch(), reportsFinancing.saga.watch()]);
}

export default {
  watch,
};
