import { isEqual } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import getApiReceiptsIdService from '@giro/shared-services/pdv/getApiReceiptsId.service';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

function* handleServiceGet(action) {
  const payload = yield select(currentStore.selector.selectPayload);

  try {
    yield put(currentStore.action.fetchStart());

    const [success, result] = yield call(getApiReceiptsIdService, payload);

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));
  } catch (e) {
    yield put(currentStore.action.fetchError());
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
