import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ScreenLayout = ({
  children,
  onFilter,
  title,
  cta,
}: {
  children: any;
  onFilter?: any;
  cta?: any;
  title: string;
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="trasso_heading_medium" color="trasso.pink">
          {title}
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          {cta && cta}
          {onFilter && (
            <Button
              variant="outlined"
              sx={{
                p: 0,
                height: '48px',
                minWidth: '48px',
                width: '48px',
                color: 'trasso.purple.60',
                border: '1px solid transparent',
                borderColor: 'trasso.purple.60',
                borderWidth: 2,
                borderRadius: '10px',
                '&:hover': {
                  borderWidth: 2,
                },
              }}
            >
              <svg
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <use href="/feather-sprite.svg#filter" />
              </svg>
            </Button>
          )}
        </Box>
      </Box>

      <Box mb={4} mt={4} height={2} bgcolor="#E4DAEB" />

      <Box>{children}</Box>
    </Box>
  );
};

export default ScreenLayout;
