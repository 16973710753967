import { isEqual } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import getApiReceiptsIdService from '@giro/shared-services/pdv/getApiReceiptsId.service';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

function* handleOpen(action) {
  yield call(() => localStorage.setItem('menuVisible', 'true'));
}
function* handleClose(action) {
  yield call(() => localStorage.setItem('menuVisible', 'false'));
}

function* watch() {
  yield takeLatest(currentStore.constant.ACTION_TYPES.SET.OPEN, handleOpen);
  yield takeLatest(currentStore.constant.ACTION_TYPES.SET.CLOSE, handleClose);
}

export default {
  watch,
};
