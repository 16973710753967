import { createReducer } from '@reduxjs/toolkit';

import initialState from './monitorings.initialState';
import { ACTION_TYPES } from './monitorings.constant';

const handleResetState = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchError = (state) => {
  state.loading = false;
};

const handleFetchSuccess = (state, action) => {
  state.loading = false;
  state.data = action.payload;
};

const handleUpdateFilters = (state, action) => {
  state.filters = action.filters;
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.FETCH.SUCCESS]: handleFetchSuccess,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
});
