import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';
import * as authCookie from '@giro/shared-utils/authCookie.util';

export default async function getApiAppsService(filters, auth = true) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['debts', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'DEBTS',
    auth,
  });
}
