import React from 'react';

const useFetchHook = (
  service,
  configs: any = {
    requestOnMount: false,
  }
) => {
  const [dom, setDom] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);

  React.useEffect(() => {
    setDom(true);
  }, []);

  React.useEffect(() => {
    if (!dom) return undefined;

    if (configs.requestOnMount) fetchData();
  }, [dom]);

  const fetchData = async (...args) => {
    let successLet, dataLet;

    setError(null);
    setLoading(true);

    try {
      const response = await service(...args);

      const [success, data] = response || [];

      successLet = success;
      dataLet = data;

      if (!success) throw data;

      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }

    return [successLet, dataLet];
  };

  return {
    data,
    loading,
    error,
    isSuccess: !error && loading === false && !!data,
    handleFetch: fetchData,
  };
};

export default useFetchHook;
