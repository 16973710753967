import reducer from './toaster.reducer';
import * as action from './toaster.action';
import * as selector from './toaster.selector';
import * as constant from './toaster.constant';
import initialState from './toaster.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  name: constant.NAME,
};
