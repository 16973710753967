import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';

type FiltersType = {
  dateStart: string;
  dateEnd: string;
  type: 'crea' | 'ec';
};

export default async function getApiReportsService(filters: FiltersType) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['reports', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'PDV',
    auth: true,
  });
}
