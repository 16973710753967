import { isEqual } from 'lodash';
import { takeLatest, put, select, delay } from 'redux-saga/effects';

import toaster from '@giro/shared-store/toaster';

import dialogData from '.';

function* handleReset() {
  const state = yield select(dialogData.selector.selectState);

  if (isEqual(dialogData.initialState, state)) {
    return true;
  }

  yield put(dialogData.action.reset());
}

function* handleServicePost(action) {
  const { payload } = action;

  yield put(dialogData.action.fetchStart());

  yield delay(2000);

  yield put(dialogData.action.fetchSuccess());
  yield put(dialogData.action.close());
  yield put(
    toaster.action.show({
      message: 'Link de pagamento enviado com sucesso!',
      variant: 'success',
    })
  );
}

function* watch() {
  yield takeLatest(
    dialogData.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
}

export default {
  watch,
};
