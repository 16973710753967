import mock from '@giro-pdv/mocks/state.mock.json';

const initialState = {
  amount: 0,
  loading: null,
  installmentSelected: null,
  typeTax: 'client',
  return: 0,
};

// export default mock.pdv.tablePayment;
export default initialState;
