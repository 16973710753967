import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  integration_id: string;
  placa?: string;
  renavam?: string;
  document_number?: string;
};

export default async function postApiMonitoringsService(body: BodyType) {
  return fetch(`monitorings`, {
    method: 'POST',
    ms: 'MONITORING',
    auth: true,
    body: JSON.stringify(body),
  });
}
