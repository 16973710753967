import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import useDialogHook from '@giro-pdv/hooks/useDialogRedux.hook';

import messagesSchema from '@giro/shared-constants/messagesSchema.constant';

import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import dialogChangeEmail from '../store/dialogs/dialogChangeEmail';

export default function DialogForgotPassword() {
  const { handleClose, visible } = useDialogHook(dialogChangeEmail);

  const schema = Yup.object({
    email: Yup.string()
      .email(messagesSchema.EMAIL)
      .required(messagesSchema.REQUIRED),
  });

  const initialValues = {
    email: '',
  };

  return (
    <Dialog open={visible} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount
        validationSchema={schema}
      >
        {({ errors }) => (
          <Form>
            <DialogTitle>
              <Typography variant="trasso_body_medium" color="trasso.purple.80">
                Esqueci minha senha
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="trasso_body_medium">
                  Digite abaixo o email no qual a conta está cadastrada,
                  enviaremos por email as instruções para você trocar a senha.
                </Typography>
              </DialogContentText>
              <Box mt={2}>
                <FieldLabelFormikComponent
                  name="email"
                  autoFocus
                  type="email"
                  fullWidth
                  label="Email"
                  size="small"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fechar</Button>
              <Button
                onClick={handleClose}
                disabled={Object.keys(errors).length > 0}
              >
                Enviar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
