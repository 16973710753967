import React from 'react';
import { flow } from 'lodash';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import { BsPlusSlashMinus } from 'react-icons/bs';
import { MdOutlineAttachMoney } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { Paper } from '@mui/material';
import useIsMobile from '@giro-pdv/hooks/useIsMobile.hook';
import ReactExport from 'react-export-excel';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import dialogReceipt from '@giro-pdv/store/dialogReceipt';

import reports from '../store/reports';
import { IconContext } from 'react-icons/lib';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function TableReportCreaComponent() {
  const { user } = useAuthHook();

  const isBlockedSelect = user?.user?.codes?.idRole === 6;

  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const dispatch = useDispatch();

  const selectorRedux = {
    data: useSelector(reports.selector.selectData),
    loading: useSelector(reports.selector.selectLoading),
  };

  const dispatchRedux = {
    updateFilters: flow(reports.action.updateFilters, dispatch),
    resetState: flow(reports.action.resetState, dispatch),
    receiptOpen: flow(dialogReceipt.action.open, dispatch),
  };

  const handleExport = () => {
    const exportButton = document.querySelector(
      '.button-xls-export'
    ) as HTMLElement;

    if (exportButton) {
      exportButton.click();
    }
  };

  React.useEffect(() => {
    dispatchRedux.updateFilters({
      type: 'ec',
      userCode: !isBlockedSelect ? undefined : user?.user?.codes?.idUser,
    });

    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const currentData = selectorRedux.data?.results;
  const currentMetadata = selectorRedux.data?.metadata;

  const renderTableCell = (el, idx, row) => {
    const isDate = ['DtTransacao'];
    const isAmount = ['VlTransacao', 'VlLiberado', 'VlTaxas'];
    const isCustom = ['receipt'];

    if (isCustom.includes(el)) {
      if (el === 'receipt') {
        return (
          <TableCell component="th" scope="row" key={`${el}-${idx}`}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ height: 32, width: 32, alignSelf: 'flex-end' }}
              onClick={() => dispatchRedux.receiptOpen(row.CdTransacao)}
              disabled={row.Status !== 'Aprovada'}
            >
              <svg
                width="16"
                height="16"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <use href="/feather-sprite.svg#printer" />
              </svg>
            </Button>
          </TableCell>
        );
      }
    }

    if (isDate.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {format(new Date(row[el].replace('Z', '')), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </TableCell>
      );
    }

    if (isAmount.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {Number(row[el]).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </TableCell>
      );
    }

    return (
      <TableCell component="th" scope="row" key={`${el}-${idx}`}>
        <Typography variant="trasso_body_extra_small" color="trasso.purple.100">
          {row[el]}
        </Typography>
      </TableCell>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="300px"
      position="relative"
    >
      {currentData?.length > 0 && !selectorRedux.loading && (
        <Box p={2}>
          <Box
            paddingBottom={3}
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
            }}
          >
            <Box
              sx={{
                boxShadow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                // minWidth: '250px',
              }}
              display="grid"
              gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
            >
              <Box
                bgcolor="secondary.main"
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                  <BsPlusSlashMinus />
                </IconContext.Provider>
              </Box>
              <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                <Typography
                  variant="trasso_body_medium"
                  color="grey.700"
                  fontWeight={600}
                >
                  {Number(currentMetadata?.count)}
                </Typography>
                <Typography
                  variant="trasso_heading_extra_small"
                  color="grey.500"
                >
                  Quantidade
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                boxShadow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                minWidth: '250px',
              }}
              display="grid"
              gridTemplateColumns="1fr 1fr"
            >
              <Box
                bgcolor="secondary.main"
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                  <MdOutlineAttachMoney />
                </IconContext.Provider>
              </Box>
              <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                <Typography
                  variant="trasso_body_medium"
                  color="grey.700"
                  fontWeight={600}
                >
                  {Number(currentMetadata?.valor_total).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </Typography>
                <Typography
                  variant="trasso_heading_extra_small"
                  color="grey.500"
                >
                  Valor Total
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                boxShadow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                minWidth: '250px',
              }}
              display="grid"
              gridTemplateColumns="1fr 1fr"
            >
              <Box
                bgcolor="secondary.main"
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                  <MdOutlineAttachMoney />
                </IconContext.Provider>
              </Box>
              <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                <Typography
                  variant="trasso_body_medium"
                  color="grey.700"
                  fontWeight={600}
                >
                  {Number(currentMetadata?.valor_liberado).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </Typography>
                <Typography
                  variant="trasso_heading_extra_small"
                  color="grey.500"
                >
                  Valor Líquido
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                boxShadow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                minWidth: '250px',
              }}
              display="grid"
              gridTemplateColumns="1fr 1fr"
            >
              <Box
                bgcolor="secondary.main"
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                  <MdOutlineAttachMoney />
                </IconContext.Provider>
              </Box>
              <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                <Typography
                  variant="trasso_body_medium"
                  color="grey.700"
                  fontWeight={600}
                >
                  {Number(currentMetadata?.valor_taxas).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </Typography>
                <Typography
                  variant="trasso_heading_extra_small"
                  color="grey.500"
                >
                  Valor Total de taxas
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button variant="outlined" onClick={handleExport} size="small">
              Exportar
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              sx={{ minWidth: { xl: '100%', md: '1300px' } }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  {[
                    'Data',
                    'Código de autorização (NSU)',
                    'Valor transação',
                    'Valor líquido',
                    'Tipo',
                    'Parcelas',
                    'Valor taxas',
                    'Status',
                    '',
                  ].map((el, idx) => (
                    <TableCell key={`row-${idx}`}>
                      <Typography
                        variant="trasso_heading_extra_small"
                        color="trasso.purple.100"
                      >
                        {el}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((row) => (
                  <TableRow
                    key={row.DtTransacao}
                    sx={{
                      cursor: 'pointer',
                      '&:nth-of-type(odd)': {
                        backgroundColor: 'grey.100',
                      },
                      '&:nth-of-type(even)': {
                        backgroundColor: 'white',
                      },
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },

                      '&:hover': {
                        backgroundColor: 'grey.300',
                      },
                    }}
                  >
                    {[
                      'DtTransacao',
                      'IdTransacao',
                      'VlTransacao',
                      'VlLiberado',
                      'TpPagamento',
                      'QtdParcelas',
                      'VlTaxas',
                      'Status',
                      'receipt',
                    ].map((el, idx) => renderTableCell(el, idx, row))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={currentMetadata?.count || 0}
                    rowsPerPage={currentMetadata?.limit}
                    page={currentPage - 1}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={(ev, nextPage) => {
                      dispatchRedux.updateFilters({
                        limit,
                        offset: nextPage * limit,
                      });
                      setCurrentPage(nextPage + 1);
                    }}
                    onRowsPerPageChange={(ev: any) => {
                      setLimit(ev.target.value);
                      dispatchRedux.updateFilters({
                        limit: ev.target.value,
                        offset: 0,
                      });
                      setCurrentPage(1);
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}

      {currentData?.length === 0 && !selectorRedux.loading && (
        <Typography>Nenhum dado encontrado para essa pesquisa.</Typography>
      )}

      {selectorRedux.loading && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          bgcolor="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      <ExcelFile
        filename={'Relatório'}
        element={
          <button
            className="button-xls-export"
            style={{ display: 'none' }}
          ></button>
        }
      >
        <ExcelSheet data={selectorRedux.data?.results} name="Relatório">
          <ExcelColumn
            label="Data transação"
            value={({ DtTransacao }) =>
              format(new Date(DtTransacao), 'dd/MM/yyyy HH:mm')
            }
          />
          <ExcelColumn label="Valor transação" value="VlTransacao" />
          <ExcelColumn label="Valor líquido" value="VlLiberado" />
          <ExcelColumn label="Tipo" value="TpPagamento" />
          <ExcelColumn label="Parcela" value="QtdParcelas" />
          <ExcelColumn label="Valor taxas" value="VlTaxas" />
          <ExcelColumn label="Status" value="Status" />
        </ExcelSheet>
      </ExcelFile>
    </Box>
  );
}
