import { ACTION_TYPES } from './router.constant';

export const routeChange = (payload) => ({
  type: ACTION_TYPES.ROUTE_CHANGED,
  payload,
});

export const push = (href) => ({
  type: ACTION_TYPES.PUSH,
  payload: {
    href,
  },
});

export const replace = (href) => ({
  type: ACTION_TYPES.REPLACE,
  payload: {
    href,
  },
});

export const go = (index) => ({
  type: ACTION_TYPES.GO,
  payload: {
    index,
  },
});

export const back = () => ({
  type: ACTION_TYPES.BACK,
});

export const forward = () => ({
  type: ACTION_TYPES.FORWARD,
});
