export const NAME = 'router';

const CONTEXT = 'ROUTER';

const ROUTE_CHANGED = `${CONTEXT}::ROUTE_CHANGED`;
const PUSH = `${CONTEXT}::PUSH`;
const REPLACE = `${CONTEXT}::REPLACE`;
const GO = `${CONTEXT}::GO`;
const BACK = `${CONTEXT}::BACK`;
const FORWARD = `${CONTEXT}::FORWARD`;

export const ACTION_TYPES = {
  ROUTE_CHANGED,
  PUSH,
  REPLACE,
  GO,
  BACK,
  FORWARD,
};
