import reducer from './sync.reducer';
import * as action from './sync.action';
import * as selector from './sync.selector';
import * as constant from './sync.constant';
import initialState from './sync.initialState';
import saga from './sync.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
